import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.services';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material';
import { log } from 'console';
import { Router } from '@angular/router';
declare var $: any;
import { FormGroup, FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { data } from 'jquery';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.scss']
})
export class MetadataComponent implements OnInit {

  response: any;
  metadataListTemplate: any;
  metadataListTemplateFolder: any;
  allList: any;
  deleteResponse: any;
  showSideBar = false;
  dashboardProjectlist: any;
  dashboardRecentfilelist: any;
  dashboardRecentfolderlist: any;
  dashboardRecentdetails: any; 
  uuiddelete: any;
  currentValue: any;
  addPrioritytypeForm: FormGroup;
  projectTypesubmitted: boolean;
  addPriorityResponse: any;
  priorityResponse: any;
  priority: any;
  editDatavalue: any;
  priorityData: any;
  deletePriorityId: any;
  deletePriorityResponse: any;
  copyMetadataResponse: any;
  sidebarOpen = false;
  isSidebarOpen: boolean;
  // onSidebarToggle(isOpen: boolean) {
  //   this.isSidebarOpen = isOpen;
  //   console.log(this.isSidebarOpen);
    
  // }
  

  constructor(private api: ApiService,
              private ngxUiLoaderService: NgxUiLoaderService,
              public snackBar : MatSnackBar,
              private router: Router,
              public formBuilder: FormBuilder) {
                
               }

  ngOnInit() {
    this.getMetaData();
    this.getDashboardDetails();
    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)

    this.addPrioritytypeForm = this.formBuilder.group({
      priority: ['',[Validators.required,Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ, ]+$")]],
    });
    this.getPriority();
    
  }
  
  
  get c() {
    return this.addPrioritytypeForm.controls;
  }

  getMetaData() {
    this.ngxUiLoaderService.start();
    this.api.getMetaData().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.response = data;
      if (this.response.status == 200) {
        this.metadataListTemplate = this.response.results.document;
        this.metadataListTemplateFolder = this.response.results.folder;
        this.allList = this.response.results;
        console.log(this.allList,"this.allList");
        
        this.ngxUiLoaderService.stop();
        // this.snackBar.open(this.response.message, "Dismiss", {
        //   duration:3000,
        // })
      }else{
        this.ngxUiLoaderService.stop();
        // this.snackBar.open(this.response.message, "Dismiss", {
        //   duration:3000,
        // })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  editMetaDataForm(uuid,value) {
    console.log(uuid)
    this.ngxUiLoaderService.start();
    // this.router.navigate(['/metadata-form/']);
    this.router.navigate(["/metadata-form",  uuid],{fragment:value})
    this.ngxUiLoaderService.stop();
  }
  
  getInfo(key:any) {
    console.log(key)
    if(key == '1') {
      this.showSideBar = false;
    }
    else {
      this.showSideBar = true;
    }
    
  }

  deleteTemplate(){
    this.ngxUiLoaderService.start();
    this.api.deleteTemplate(this.uuiddelete).subscribe(data=>{
      console.log(data);
      this.deleteResponse = data;
      if (this.deleteResponse.status == 200) {
        this.uuiddelete = null;
        $('#deleteModal3').modal('hide')
        $(".modal-backdrop").remove();
        this.snackBar.open(this.deleteResponse.message, "Dismiss", {
          duration:3000,
         
        })
        this.getMetaData();
        this.ngxUiLoaderService.stop();
      }else{
        this.snackBar.open(this.deleteResponse.message, "Dismiss", {
          duration:3000,
        })
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }


  getDashboardDetails() {
    this.ngxUiLoaderService.start();
    this.api.getDashboardDetails().subscribe(data=>{
      this.ngxUiLoaderService.stop();
        this.response = data;
        console.log(this.response)
        if (this.response.status == 200) {
          this.dashboardProjectlist = this.response.project_list;
          this.dashboardRecentfilelist = this.response.Recent_files;
          this.dashboardRecentfolderlist = this.response.Folder_list;
          this.dashboardRecentdetails = this.response.details;
        }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  deleteMetadata(uuid){
    this.uuiddelete = uuid;
    $('#deleteModal3').modal('show');
  }
  opencreateModal(item=null){
    if(item !== null){
      console.log('hloooooo',item);
      this.editDatavalue = item;
      
      this.addPrioritytypeForm.get('priority').setValue(this.editDatavalue.name)
    }
    $('#addProjectTypeModal').modal('show');
  }

  onSubmitpriorityType(){
    this.projectTypesubmitted = true;
    console.log('asdfghj',this.addPrioritytypeForm.value);

    if (this.addPrioritytypeForm.invalid) {
      return
    }
    
    this.priorityData = this.addPrioritytypeForm.value;
    if(this.editDatavalue){
      this.priorityData.id = this.editDatavalue.id
    }

    console.log(this.priorityData);
    
    
    this.ngxUiLoaderService.start();
    this.api.createPriority(this.priorityData).subscribe(data=>{
      console.log(data);
      this.addPriorityResponse = data;
      if(this.addPriorityResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.addPriorityResponse);
        this.snackBar.open(this.addPriorityResponse.message, "Dismiss", {
          duration:3000,
        })
        this.projectTypesubmitted = false;
        $('#addProjectTypeModal').modal('hide');
        this.editDatavalue = null;
        this.getPriority();
        this.addPrioritytypeForm.reset();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.addPriorityResponse.message, "Dismiss", {
          duration:3000,
        })
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }
  closseModal(){
    this.projectTypesubmitted = false;
    $('#addProjectTypeModal').modal('hide');
    this.addPrioritytypeForm.reset();
  }

  getPriority(){
    this.ngxUiLoaderService.start();
    this.api.getPrioritydata().subscribe(data=>{
      console.log(data);
      this.priorityResponse = data;
      if(this.priorityResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.priorityResponse);
        this.priority = this.priorityResponse.result;
        
      }else{
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }
  
  deletepriorityDelete(id){
    console.log(id);
    this.deletePriorityId = id;
    $('#deleteModalPriority').modal('show');
    
  }
  deletePriority(){
    let data ={
      priority_id:this.deletePriorityId
    } 
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.deletePriority(data).subscribe(data=>{
      console.log(data);
      this.deletePriorityResponse = data;
      if(this.deletePriorityResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.deletePriorityResponse.message, "Dismiss", {
          duration:3000,
        })
        this.getPriority();
        this.deletePriorityId = null;
        $('#deleteModalPriority').modal('hide');
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.deletePriorityResponse.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }

  copyTemplate(item){
    console.log(item);
    // let data = item;
    var data = { file_id: item }
    this.ngxUiLoaderService.start();
    this.api.copyProjectFile(data).subscribe(data => {
      console.log(data);
      this.copyMetadataResponse = data;
      if (this.copyMetadataResponse.status == 200) {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.copyMetadataResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.getMetaData();
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.copyMetadataResponse.message, "Dismiss", {
          duration: 3000,
        })
      }

    }, error => {
    });
  }
  
  setFilteredLocations(e){
    console.log(e);
    console.log(this.allList);
    this.metadataListTemplate = this.metadataListTemplate.filter((location) => {
      if(location.template_name != null){
        return location.template_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
      }
    });
  }

  setFilteredLocationsDocument(e){
    console.log(e);
    console.log(this.allList);
    this.metadataListTemplate = this.metadataListTemplateFolder.filter((location) => {
      if(location.template_name != null){
        return location.template_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
      }
    });
  }

  onSidebarOpened(isOpen: boolean) {
    this.isSidebarOpen = isOpen;
    console.log('ye kya huaaaa');
    
  }

  folderTemplate(){
    this.router.navigate(['/metadata-form'],{fragment:'2'})
  }

  documentTemplate(){
    this.router.navigate(['/metadata-form'],{fragment:'1'})
  }

}

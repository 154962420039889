<app-sidebar></app-sidebar>
<app-header></app-header>
<main>
  <div class="container-custom">
    <div class="columns">
      <div class="main">

        <div class="heading_1  align-items-center justify-content-between" data-aos="fade-right" data-aos-delay="400"
          data-aos-duration="1500">


          <div class="title_container">
            <div class="parent-flex">
              <div class="title_left">
                <div class="d-flex align-items-center">
                  <img class="title_img" src="assets/images/recycle_blue.png" alt="">
                  <div class="title_lg">{{'Recycle Bin' | translate}}</div>
                  <!-- <div class="settingsBtn"><img src="assets/images/blueWheel.png" alt="" class="mr-2">Settings</div> -->
                </div>
                <div class="d-flex"></div>
              </div>
              <div class="endChild">
                <button class="btn bg_blue margin_0" *ngIf="selection" (click)="cancel()">{{'Cancel' | translate}}</button>
                <button class="btn_primary bg_grey" *ngIf="(!selection) && ((resposeData && resposeData.length > 0) || (deletedProjects && deletedProjects.length > 0) || (deletedFiles && deletedFiles.length > 0))"(click)="selectAction()">{{'Select' | translate}}</button>
                <button class="btn_primary bg_grey" *ngIf="selection" (click)="selectAll()">{{'Select All' |translate}}</button>
                <button class="btn_primary bg_blue" [hidden]="currentValue && (!currentValue.permissions.restoreFile)" *ngIf="selection" (click)="openRestoreModal()" [disabled]="folderOrFilesID.length == 0">{{'Restore' | translate}}</button>
                <!-- <button class="btn_primary bg_blue" *ngIf="selection" data-bs-toggle="modal" href="#deleteModal2">{{'Archive' | translate}}</button> -->
                <button class="btn_primary bg_red" *ngIf="(selection) &&  (currentValue && (currentValue.permissions.permenantDelete))" [disabled]="folderOrFilesID.length == 0" (click)="opendeleteModal()" >{{'Delete' | translate}}</button>
                <!-- <button class="btn_primary bg_blue restore_button_top" *ngIf="!selection" routerLink="/restorefiles/restoredeletedfiles" [hidden]="currentValue && (!currentValue.permissions.ViewRestorePage)">{{'Restore Deleted files1' |translate}}</button> -->
                <!-- <button class="btn_primary bg_blue margin_0" [hidden]="currentValue && (!currentValue.permissions.viewDestructionReport)" *ngIf="!selection" routerLink="/restorefiles/deletepermanentfiles">{{'Destruction Reports' | translate}}</button> -->

                <img (click)="gridActive('listActive')" *ngIf="gridView" class="cursor_pointer" src="assets/images/threeLine.png" alt="">
                <img *ngIf="gridView" class="cursor_pointer" src="assets/images/gridActive.png" alt="">
                <img *ngIf="!gridView" class="cursor_pointer" src="assets/images/threeLineActive.png" alt="">
                <img (click)="gridActive('gridActive')" *ngIf="!gridView" class="cursor_pointer" src="assets/images/grid.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- GRID VIEW  -->
        <div id="listView" class="binTable" *ngIf="!gridView">
          <div class="wrapper" *ngIf="(resposeData && resposeData.length > 0)">
            <div class="Rtable Rtable--5cols Rtable--collapse">
              <div>
                <h5>
                  <!-- <img src="assets/images/folder_grey.png" alt="" class="title_sm_logo">  -->
                  {{'Deleted Folders' | translate}}
                </h5>
              </div>
              <div class="Rtable-row Rtable-row--head">
                <div class="Rtable-cell name-cell column-heading">{{'Name' | translate}}&nbsp;<i
                    class="fa-solid fa-arrow-up"></i></div>
                <div class="Rtable-cell size-cell column-heading">{{'File Size' | translate}}</div>
                <div class="Rtable-cell date-cell column-heading">{{'Deleted date' | translate}}</div>
                <div class="Rtable-cell location-cell column-heading">{{'Orginal location' | translate}}</div>
              </div>

              <div class="clickable" *ngFor="let data of resposeData index as i">
                <div class="Rtable-row is-striped" (contextmenu)="open($event, data); $event. preventDefault();">
                  <div class="Rtable-cell name-cell">
                    <div class="Rtable-cell--content title-content"><mat-checkbox [(ngModel)]="data.completed"
                        (ngModelChange)="updateSingleFile(data,$event)" *ngIf="selection"
                        class="binSelect"></mat-checkbox> {{data.folder_name}} </div>
                  </div>
                  <div class="Rtable-cell size-cell">
                    <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                    <div class="Rtable-cell--content replay-link-content">{{data.size}} </div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">{{'Deleted date' | translate}}</div>
                    <div class="Rtable-cell--content date-content"><span class="">{{data.updated_at}}</span></div>
                  </div>
                  <div class="Rtable-cell location-cell">
                    <div class="Rtable-cell--heading">{{'Orginal location' | translate}}</div>
                    <div class="Rtable-cell--content access-link-content"><i class="ion-link"></i>
                      <div class="Rtable-cell--content d-flex align-items-center"><img src="assets/images/folder_sm.png"
                          alt="">{{data.original_location}}
                      </div>
                    </div>
                  </div>

                </div>
                <!-- <div class="pointer">
                    <div class="pointor_options mb-15"><img src="assets/images/time.png" alt=""> {{'Restore' | translate}}</div>
                    <div class="pointor_options" (click)="deleteForever()"><img src="assets/images/delete-sm.png" alt=""> {{'Delete Forever' | translate}}</div>
                </div> -->
                <ng-template #userMenu let-user>
                  <section class="user-menu" [hidden]="currentValue && (!currentValue.permissions.restoreFile && !currentValue.permissions.permenantDelete)">
                    <!-- <div (click)="delete(user)">Delete {{user.name}}</div> -->
                    <div (click)="restoreFileorFolder(user.id)"><img src="assets/images/time.png" alt=""> {{'Restore' |
                      translate}}</div>
                    <div (click)="deleteForever(user.id)"><img src="assets/images/delete-sm.png" alt=""> {{'Delete
                      Forever' | translate}}</div>
                  </section>
                </ng-template>

              </div>

            </div>
            <div *ngIf="resposeData && resposeData.length == 0" class="text-center">
              <img src="assets/images/doc.png" class="img-fluid mb-2">
              <p>{{'No Deleted Folders Found' | translate}}</p>
            </div>
          </div>
        </div>

        <div id="listView" class="binTable" *ngIf="!gridView">
          <div class="wrapper" *ngIf="(deletedFiles && deletedFiles.length > 0)">
            <div class="Rtable Rtable--5cols Rtable--collapse">
              <div>
                <h5>
                  <!-- <img src="assets/images/document_grey.png" alt="" class="title_sm_logo">  -->
                  {{'Deleted Files' | translate}}
                </h5>
              </div>
              <div class="Rtable-row Rtable-row--head">
                <div class="Rtable-cell name-cell column-heading">{{'Name' | translate}}&nbsp;<i
                    class="fa-solid fa-arrow-up"></i></div>
                <div class="Rtable-cell size-cell column-heading">{{'File Size' | translate}}</div>
                <div class="Rtable-cell date-cell column-heading">{{'Deleted date' | translate}}</div>
                <div class="Rtable-cell location-cell column-heading">{{'Orginal location' | translate}}</div>
              </div>

              <div class="clickable" *ngFor="let data of deletedFiles index as i" (contextmenu)="open($event, data); $event. preventDefault();">
                <div class="Rtable-row is-striped" >
                  <div class="Rtable-cell name-cell">
                    <div class="Rtable-cell--content title-content"><mat-checkbox [(ngModel)]="data.completed"
                        (ngModelChange)="updateSingleFile(data,$event)" *ngIf="selection"
                        class="binSelect"></mat-checkbox> {{data.file_name}}</div>
                  </div>
                  <div class="Rtable-cell size-cell">
                    <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                    <div class="Rtable-cell--content replay-link-content">{{data.file_size}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">{{'Deleted date' | translate}}</div>
                    <div class="Rtable-cell--content date-content"><span class="">{{data.updated_at}}</span></div>
                  </div>
                  <div class="Rtable-cell location-cell">
                    <div class="Rtable-cell--heading">{{'Orginal location' | translate}}</div>
                    <div class="Rtable-cell--content access-link-content"><i class="ion-link"></i>
                      <div class="Rtable-cell--content d-flex align-items-center"><img src="assets/images/folder_sm.png"
                          alt="">{{data.original_location}}
                      </div>
                    </div>
                  </div>

                </div>


                <!-- <div class="pointer">
                    <div class="pointor_options mb-15"><img src="assets/images/time.png" alt=""> {{'Restore' | translate}}</div>
                    <div class="pointor_options" (click)="deleteForever()"><img src="assets/images/delete-sm.png" alt=""> {{'Delete Forever' | translate}}</div>
                </div> -->
                <ng-template #userMenu let-user>
                  <section class="user-menu" [hidden]="currentValue && (!currentValue.permissions.restoreFile && !currentValue.permissions.permenantDelete)">
                    <!-- <div (click)="delete(user)">Delete {{user.name}}</div> -->
                    <div (click)="restoreFileorFolder(user.id)"><img src="assets/images/time.png" alt=""> {{'Restore' |
                      translate}}</div>
                    <div (click)="deleteForever(user.id)"><img src="assets/images/delete-sm.png" alt=""> {{'Delete
                      Forever' | translate}}</div>
                  </section>
                </ng-template>

              </div>


            </div>
            <div *ngIf="deletedFiles && deletedFiles.length == 0" class="text-center">
              <img src="assets/images/doc.png" class="img-fluid mb-2">
              <p>{{'No Deleted Files Found' | translate}}</p>
            </div>
          </div>
        </div>


        <div id="listView" class="binTable" *ngIf="!gridView">
          <div class="wrapper" *ngIf="(deletedProjects && deletedProjects.length > 0)">
            <div class="Rtable Rtable--5cols Rtable--collapse">
              <div>
                <h5>
                  <!-- <img src="assets/images/wheel_doc.png" alt="" class="title_sm_logo">  -->
                  {{'Deleted Projects' | translate}}
                </h5>
              </div>
              <div class="Rtable-row Rtable-row--head">
                <div class="Rtable-cell name-cell column-heading">{{'Name' | translate}}&nbsp;<i
                    class="fa-solid fa-arrow-up"></i></div>
                <div class="Rtable-cell size-cell column-heading">{{'File Size' | translate}}</div>
                <div class="Rtable-cell date-cell column-heading">{{'Deleted date' | translate}}</div>
                <div class="Rtable-cell location-cell column-heading">{{'Orginal location' | translate}}</div>
              </div>

              <div class="clickable" *ngFor="let data of deletedProjects index as i" >
                
                  <div class="Rtable-row is-striped" (contextmenu)="open($event, data); $event. preventDefault();" >
                    <div class="Rtable-cell name-cell">
                      <div class="Rtable-cell--content title-content"><mat-checkbox [(ngModel)]="data.completed"
                          (ngModelChange)="updateSingleFile(data,$event)" *ngIf="selection"
                          class="binSelect"></mat-checkbox> {{data.name}} </div>
                    </div>
                    <div class="Rtable-cell size-cell">
                      <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                      <div class="Rtable-cell--content replay-link-content">{{data.size}}</div>
                    </div>
                    <div class="Rtable-cell date-cell">
                      <div class="Rtable-cell--heading">{{'Deleted date' | translate}}</div>
                      <div class="Rtable-cell--content date-content"><span class="">{{data.updated_at}}</span></div>
                    </div>
                    <div class="Rtable-cell location-cell">
                      <div class="Rtable-cell--heading">{{'Orginal location' | translate}}</div>
                      <div class="Rtable-cell--content access-link-content"><i class="ion-link"></i>
                        <div class="Rtable-cell--content d-flex align-items-center"><img src="assets/images/folder_sm.png"
                            alt="">{{data.original_location}}
                        </div>
                      </div>
                    </div>
                  </div>

                
                <!-- <div class="pointer">
                    <div class="pointor_options mb-15"><img src="assets/images/time.png" alt=""> {{'Restore' | translate}}</div>
                    <div class="pointor_options" (click)="deleteForever()"><img src="assets/images/delete-sm.png" alt=""> {{'Delete Forever' | translate}}</div>
                </div> -->
                <ng-template #userMenu let-user >
                  <section class="user-menu" [hidden]="currentValue && (!currentValue.permissions.restoreFile && !currentValue.permissions.permenantDelete)">
                    <!-- <div (click)="delete(user)">Delete {{user.name}}</div> -->
                    <div (click)="restoreFileorFolder(user.id)"><img src="assets/images/time.png" alt=""> {{'Restore' |
                      translate}}</div>
                    <div (click)="deleteForever(user.id)"><img src="assets/images/delete-sm.png" alt=""> {{'Delete
                      Forever' | translate}}</div>
                  </section>
                </ng-template>

              </div>

            </div>
            <div *ngIf="deletedProjects && deletedProjects.length == 0" class="text-center">
              <img src="assets/images/doc.png" class="img-fluid mb-2">
              <p>{{'No Deleted Projects Found' | translate}}</p>
            </div>

          </div>



        </div>

        <!-- <div *ngIf="(resposeData && resposeData.length == 0) && (deletedProjects && deletedProjects.length == 0) && (deletedFiles && deletedFiles.length == 0)" class="text-center">
          <img src="assets/images/doc.png" class="img-fluid mb-2">
          <p>{{'No Folders Found' | translate}}</p>
        </div> -->



        <!-- GRID VIEW CLOSE -->


        <div id="gridView" class="binTable" *ngIf="gridView">
          <div class="wrapper">
            <div *ngIf="(resposeData && resposeData.length > 0)">
              <h5>
                <!-- <img src="assets/images/folder_grey.png" alt="" class="title_sm_logo">  -->
                {{'Deleted Folders' | translate}}
              </h5>
            </div>
            <div class="Rtable Rtable--5cols Rtable--collapse">
              <div class="clickable" *ngFor="let data of resposeData index as i" (contextmenu)="open($event, 'data'); $event. preventDefault();">
                <div class="Rtable-row is-striped">
                  <div class="Rtable-cell name-cell">
                    <div class="Rtable-cell--content title-content nameGrid"><mat-checkbox [(ngModel)]="data.completed"
                        (ngModelChange)="updateSingleFile(data,$event)" *ngIf="selection"
                        class="binSelect"></mat-checkbox>{{data.folder_name}}</div>
                  </div>
                  <div class="Rtable-cell size-cell">
                    <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                    <div class="Rtable-cell--content replay-link-content">{{data.size}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">{{'Deleted date' | translate}}</div>
                    <div class="Rtable-cell--content date-content"><span class="">{{data.updated_at}}</span></div>
                  </div>
                  <div class="Rtable-cell location-cell">
                    <div class="Rtable-cell--heading">{{'Orginal location' | translate}}</div>
                    <div class="Rtable-cell--content access-link-content"><i class="ion-link"></i>
                      <div class="Rtable-cell--content d-flex align-items-center imageGrid"><img
                          src="assets/images/folder_sm.png" alt=""><span>{{data.original_location}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          <div class="wrapper">
            <div *ngIf="(deletedFiles && deletedFiles.length > 0)">
              <h5>
                {{'Deleted Files' | translate}}
              </h5>
            </div>
            <div class="Rtable Rtable--5cols Rtable--collapse" (contextmenu)="open($event, 'data'); $event. preventDefault();">

              <div class="clickable" *ngFor="let data of deletedFiles index as i">
                <div class="Rtable-row is-striped">
                  <div class="Rtable-cell name-cell">
                    <div class="Rtable-cell--content title-content nameGrid"><mat-checkbox [(ngModel)]="data.completed"
                        (ngModelChange)="updateSingleFile(data,$event)" *ngIf="selection"
                        class="binSelect"></mat-checkbox>{{data.file_name}}</div>
                  </div>
                  <div class="Rtable-cell size-cell">
                    <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                    <div class="Rtable-cell--content replay-link-content">{{data.file_size}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">{{'Deleted date' | translate}}</div>
                    <div class="Rtable-cell--content date-content"><span class="">{{data.updated_at}}</span></div>
                  </div>
                  <div class="Rtable-cell location-cell">
                    <div class="Rtable-cell--heading">{{'Orginal location' | translate}}</div>
                    <div class="Rtable-cell--content access-link-content"><i class="ion-link"></i>
                      <div class="Rtable-cell--content d-flex align-items-center imageGrid"><img
                          src="assets/images/folder_sm.png" alt=""><span>{{data.original_location}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            
          </div>
          <div class="wrapper">
            <div *ngIf="(deletedProjects && deletedProjects.length > 0)">
              <h5>
                <!-- <img src="assets/images/wheel_doc.png" alt="" class="title_sm_logo">  -->
                {{'Deleted Projects' | translate}}
              </h5>
            </div>
            <div class="Rtable Rtable--5cols Rtable--collapse" >
              <div class="clickable" *ngFor="let data of deletedProjects index as i" (contextmenu)="open($event, 'data'); $event. preventDefault();">
                <div class="Rtable-row is-striped">
                  <div class="Rtable-cell name-cell" >
                    <div class="Rtable-cell--content title-content nameGrid"><mat-checkbox [(ngModel)]="data.completed"
                        (ngModelChange)="updateSingleFile(data,$event)" *ngIf="selection"
                        class="binSelect"></mat-checkbox>{{data.name}}</div>
                  </div>
                  <div class="Rtable-cell size-cell">
                    <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                    <div class="Rtable-cell--content replay-link-content">{{data.size}}</div>
                  </div>
                  <div class="Rtable-cell date-cell">
                    <div class="Rtable-cell--heading">{{'Deleted date' | translate}}</div>
                    <div class="Rtable-cell--content date-content"><span class="">{{data.updated_at}}</span></div>
                  </div>
                  <div class="Rtable-cell location-cell">
                    <div class="Rtable-cell--heading">{{'Orginal location' | translate}}</div>
                    <div class="Rtable-cell--content access-link-content"><i class="ion-link"></i>
                      <div class="Rtable-cell--content d-flex align-items-center imageGrid"><img
                          src="assets/images/folder_sm.png" alt="">
                        <span>{{data.original_location}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <ng-template #userMenu let-user>
            <section class="user-menu" [hidden]="currentValue && (!currentValue.permissions.restoreFile && !currentValue.permissions.permenantDelete)">
              <!-- <div (click)="delete(user)">Delete {{user.name}}</div> -->
              <div (click)="restoreFileorFolder(user.id)"><img src="assets/images/time.png" alt=""> {{'Restore' |
                translate}}</div>
              <div (click)="deleteForever(user.id)"><img src="assets/images/delete-sm.png" alt=""> {{'Delete Forever' |
                translate}}</div>
            </section>
          </ng-template>
          
          
        </div>


      </div>

    </div>

    <div class="filesNotFoundClass mt-5"
      *ngIf="(resposeData && resposeData.length == 0) && (deletedProjects && deletedProjects.length == 0) && (deletedFiles && deletedFiles.length == 0)">
      <div class=" NofilesFound text-center">
        <img src="assets/images/norecycle.jpg" class="img-fluid mb-2">
        <p>{{'No Recycled files Found' | translate}}</p>
      </div>
    </div>
    

  </div>


  <div class="modal fade" id="deleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true" >
    <div class="modal-dialog modal-dialog-centered" >
      <div class="modal-content" >
        <div id="delete_id" class="modal_header">
          <h5>{{'Delete forever' | translate }} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">{{'Do you want to delete' | translate}} {{'permanently' |translate}} ?</div>
        </div>
        <div class="btnGrp mt-4">
          <!-- <button class="btn_primary bg_blue" *ngIf="currentValue && (currentValue.permissions.archiveDelete)" (click)="restoreLater()">{{'Restore later' | translate}}</button> -->
          <button *ngIf="currentValue && (currentValue.permissions.permenantDelete)" class="btn_primary bg_red"
            (click)="bulkdeletePermanentandLater()">{{'Delete Permanently' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteModal2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div id="modalrestore_id" class="modal-content">
        <div class="modal_header">
          <h5>{{'Restore' | translate}} ?</h5>
          <button type="button" #closebutton2 class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>{{'Do you want to Restore' | translate}}</div>
        </div>
        <div class="btnGrp mt-4">
          <button class="btn_primary bg_blue" (click)="closeModal1()">{{'Cancel' |
            translate}}</button>
          <button class="btn_primary bg_red" (click)="restoreFiles()">{{'Restore' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="deleteModal3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
          <h5>{{'Restore' | translate}} ?</h5>
          <button type="button" #closebutton2 class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">{{'Do you want to Restore' | translate}} ?</div>
        </div>
        <div id="restore_id" class="btnGrp mt-4">
          <button class="btn_primary bg_blue" (click)="closeModal()" href="">{{'Cancel' |
            translate}}</button>
          <button class="btn_primary bg_red" (click)="bulkRestore()">{{'Restore' | translate}}</button>
        </div>

      </div>
    </div>
  </div>
</main>
import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatSnackBar } from '@angular/material';
import { ApiService } from '../../_services/api.services';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
declare var $: any;
import { AuthenticationService } from './../../_services/authentication.service';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {

  public roleForm: FormGroup; 
  public permissionForm: FormGroup; 
  checkBoxTrue = true;
  response:any;
  permissionList: any;
  rolesubmitted = false;
  responserole: any;
  permissionlabels: any;
  responsedelete: any;
  responsesubmit: any;
  nameforFetch: any;
  userData: any;
  useruuid: any;
  EditroleForm: FormGroup;
  responseUpdateRole: any;

  constructor(public snackBar : MatSnackBar,
              private ngxUiLoaderService: NgxUiLoaderService,
              private api: ApiService,
              public formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.getPermissions();
    this.roleForm = this.formBuilder.group({
      role_name : ['',[Validators.required, Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ0-9, ]+$")]]
    });
    this.permissionForm = this.formBuilder.group({
      permissions: this.formBuilder.array([''])
    });

    this.EditroleForm = this.formBuilder.group({
      designation_id:[''],
      role_name : ['',[Validators.required, Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ0-9, ]+$")]]
    });
    this.dataFormlocalStorage();
  }

  get f() {
    return this.roleForm.controls;
  }

  get g(){
    return this.EditroleForm.controls;
  }

  getPermissions() {
    this.ngxUiLoaderService.start();
    this.api.getPermissions().subscribe(data=>{
      this.response = data;
      if (this.response.status == 200) {
        this.ngxUiLoaderService.stop();
        this.permissionList = this.response.results;
        this.permissionlabels = this.response.labels;
        console.log(this.permissionList);
        for (let key in this.permissionList) {
          console.log(this.permissionList[key])
          var designations = this.permissionForm.controls.permissions as FormArray;
          designations.controls[key] = this.formBuilder.group({
                                    permissionsforRoles: this.formBuilder.array(['']),
                                    designation_id: ['']
                                  }) 
          designations.controls[key].controls.designation_id.setValue(this.permissionList[key].designation_id) 
          for (let key1 in this.permissionList[key].permissions) { 
            var permissionsforRoles = designations.controls[key].controls.permissionsforRoles as FormArray;  
            var name = this.permissionList[key].permissions[key1].name;
            permissionsforRoles.controls[key1] = this.formBuilder.group({
              [name]: ['']
            })
            permissionsforRoles.controls[key1].get(name).setValue(this.permissionList[key].permissions[key1].value);                                          
          }                         
        }
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 


  dataFormlocalStorage(){
    let data = localStorage.getItem("currentUserDetails");
    console.log(data);
    let userDataObject = JSON.parse(data);
    this.userData = userDataObject.user_details.description;
    console.log(this.userData);
    
    this.nameforFetch = userDataObject.permissions;
    console.log(this.nameforFetch);
  }



  closseModal(){
    this.resetFormAndModal();
  }

  resetFormAndModal() {
    this.roleForm.reset();
    this.rolesubmitted = false;
  }

  onSubmitnewRole() {
    this.rolesubmitted = true;
    if(this.roleForm.invalid) {
      return
    }
    var data = this.roleForm.value;
    this.ngxUiLoaderService.start();
    this.api.addroles(data).subscribe(data=>{
      this.responserole = data;
      if (this.responserole.status == 200) {
        this.ngxUiLoaderService.stop();
        $('#deleteModal').modal('hide');
        $(".modal-backdrop").remove();
        this.getPermissions();
        this.rolesubmitted = false;
        this.roleForm.reset();
      }
      this.ngxUiLoaderService.stop();
      this.snackBar.open(this.responserole.message, "Dismiss", {
        duration:3000,
      })
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  savePermissions() {
    console.log(this.permissionForm)
    var data = [];
    console.log(this.permissionForm.controls.permissions['controls'])
    for (let key in this.permissionForm.controls.permissions['controls']) {
      this.permissionForm.controls.permissions['controls'][key]['controls'].permissionsforRoles.updateValueAndValidity();
      data.push(this.permissionForm.controls.permissions['controls'][key].value)
    } 
    var data2 = {
      rules: data
    }
    this.ngxUiLoaderService.start();
    this.api.savePermissions(data2).subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responsesubmit = data;
      if (this.responsesubmit.status == 200) {
        this.getPermissions();
        this.authenticationService.getAccounts();
      }
      this.snackBar.open(this.responsesubmit.message, "Dismiss", {
        duration:3000,
      })
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getvalue(val,name, i, key){
    console.log(val)
    console.log(name)
    console.log(this.refCompetitorNames.at(i).value)
  }

  get refCompetitorNames() {
    return this.permissionForm.get('permissions') as FormArray;
  }


  deleteRole(){
    var data = {
      roles_id: this.useruuid
    }
    this.ngxUiLoaderService.start();
    this.api.deleteRole(data).subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responsedelete = data;
      if (this.responsedelete.status == 200) {
        this.useruuid = null;
        this.getPermissions();
        $('#deleteModal3').modal('hide'); 
        $(".modal-backdrop").remove(); 
      }
      this.snackBar.open(this.responsedelete.message, "Dismiss", {
        duration:3000,
      })
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  deleteRoleList(designation_id){
    this.useruuid = designation_id;
    console.log(this.useruuid);
    $('#deleteModal3').modal('show');
  }

  openRoleModal(){
    $('#deleteModal').modal('show');
  }
  openDesgnation(item){
    console.log(item);
    this.EditroleForm.get('designation_id').setValue(item.designation_id);
    this.EditroleForm.get('role_name').setValue(item.description);
    $('#deignationEditModal').modal('show');
  }
  closseroleUpdateModal(){
    $('#deignationEditModal').modal('hide');
  }
  upDateRolename(){
    this.rolesubmitted = true;
    console.log(this.EditroleForm.value);
    if(this.EditroleForm.invalid){
        return
    }

    let data = this.EditroleForm.value;
    this.ngxUiLoaderService.start();
    this.api.updateROles(data).subscribe(data=>{
      console.log(data);
      this.responseUpdateRole = data;

      if(this.responseUpdateRole.status == 200){
        this.ngxUiLoaderService.stop();
        this.rolesubmitted = false;
        this.snackBar.open(this.responseUpdateRole.message, "Dismiss", {
          duration:3000,
        })
        this.getPermissions();
        $('#deignationEditModal').modal('hide');
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.responseUpdateRole.message, "Dismiss", {
          duration:3000,
        })
      }
      
    },error => {
      this.ngxUiLoaderService.stop();
    });
    
  }
}

<app-sidebar></app-sidebar>

<app-header></app-header>

<main>
    <div class="container-custom">

        <div class="columns">
            <div class="main overflow">
                <div class="grid mb-5 " [ngClass]="{'main-content-sidebar-open': isSidebarOpen}">
                    <div class="grid_child" >
                        <div class="title_container">
                            <div class="parent-flex">
                                <div class="title_left">
                                    <div class="d-flex align-items-center">
                                        <!-- <img src="assets/images/angle_left.png" alt=""> -->
                                        <div class="title_lg">{{'Document Metadata' | translate}}</div>
                                        <!-- <div class="settingsBtn"><img src="assets/images/blueWheel.png" alt="" class="mr-2">Settings</div> -->
                                    </div>
                                    <div class="d-flex"></div>
                                </div>
                                <div class="endChild">
                                    <button (click)="documentTemplate()" class="btn_primary bg_blue"
                                        *ngIf="currentValue && (currentValue.permissions.manageTemplates)">{{'Create templates' | translate}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="search_div mt-4 mb-4" >
                            <form action="">
                                <input _ngcontent-ldl-c7="" formcontrolname="name" maxlength="70" ng-reflect-maxlength="70" ng-reflect-name="name" 
                                placeholder="{{'Search metadata' | translate}}" class="filter_search" (keyup)="setFilteredLocations($event)" (keydown)="setFilteredLocations($event)">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </form>
                        </div>

                        <div class="template_add hideDate" id="templateDetails">
                            <div class="inputs">
                                <div class="position-relative input_container mb-16 cursor_pointer"
                                    *ngFor="let item of metadataListTemplate">
                                    <input type="text" readonly placeholder="{{ item.template_name }}" name="" id=""
                                        value="{{ item.template_name }}">
                                    <img *ngIf="currentValue && (currentValue.permissions.manageTemplates)"
                                        class="pencil" src="assets/images/pencil.png" alt=""
                                        (click)="editMetaDataForm(item.uuid,'1')">
                                    <img *ngIf="currentValue && (currentValue.permissions.manageTemplates)"
                                        class="deleteIcon" src="assets/images/delete.png" alt=""
                                        (click)="deleteMetadata(item.uuid)"
                                        [hidden]="item.template_name == 'Default Template'">
                                    <img class="copy" *ngIf="currentValue && (currentValue.permissions.manageTemplates)" alt="" src="assets/images/copy_oprtion.png" (click)="copyTemplate(item.uuid)">
                                </div>
                            </div>

                        </div>
                        <div *ngIf="metadataListTemplate && metadataListTemplate.length == 0" class="text-center mt-5">
                            <img src="assets/images/notemplates.jpg" class="img-fluid mb-2">
                            <p>{{'No Templates Found' | translate}}</p>
                        </div>
                    </div>
                    <div class="grid_child2">
                        <div class="title_container">
                            <div class="parent-flex">
                                <div class="title_left">
                                    <div class="d-flex align-items-center">
                                        <div class="title_lg">{{'Priority' | translate}}</div>
                                    </div>
                                    <div class="d-flex"></div>
                                </div>
                                <div class="endChild">
                                    <button class="btn_primary bg_blue" (click)="opencreateModal()">{{'Create Priority'|
                                        translate}}</button>
                                </div>
                            </div>
                        </div>

                        <div class="template_add hideDate" id="templateDetails">
                            <div class="inputs">
                                <div class="position-relative input_container mb-16 cursor_pointer"
                                    *ngFor="let item of priority">
                                    <input type="text" readonly placeholder="{{item.name}}" name="" id=""
                                        value="{{ item.name}}">
                                    <img *ngIf="currentValue && (currentValue.permissions.manageTemplates)"
                                        class="pencil" src="assets/images/pencil.png" alt=""
                                        (click)="opencreateModal(item)">
                                    <img *ngIf="currentValue && (currentValue.permissions.manageTemplates)"
                                        class="deleteIcon" src="assets/images/delete.png" alt=""
                                        (click)="deletepriorityDelete(item.id)">
                                </div>
                            </div>

                        </div>
                        <div *ngIf="priority && priority.length == 0" class="text-center mt-5">
                            <img src="assets/images/notemplates.jpg" class="img-fluid mb-2">
                            <p>{{'No Priority Found' | translate}}</p>
                        </div>
                    </div>
                    <div class="grid_child mt-4" >
                        <div class="title_container">
                            <div class="parent-flex">
                                <div class="title_left">
                                    <div class="d-flex align-items-center">
                                        <!-- <img src="assets/images/angle_left.png" alt=""> -->
                                        <div class="title_lg">{{'Folder Metadata' | translate}}</div>
                                        <!-- <div class="settingsBtn"><img src="assets/images/blueWheel.png" alt="" class="mr-2">Settings</div> -->
                                    </div>
                                    <div class="d-flex"></div>
                                </div>
                                <div class="endChild">
                                    <button (click)="folderTemplate()" class="btn_primary bg_blue"
                                        *ngIf="currentValue && (currentValue.permissions.manageTemplates)">{{'Create templates' | translate}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="search_div mt-4 mb-4" >
                            <form action="">
                                <input _ngcontent-ldl-c7="" formcontrolname="name" maxlength="70" ng-reflect-maxlength="70" ng-reflect-name="name" 
                                placeholder="{{'Search metadata' | translate}}" class="filter_search" (keyup)="setFilteredLocationsDocument($event)" (keydown)="setFilteredLocationsDocument($event)">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </form>
                        </div>

                        <div class="template_add hideDate" id="templateDetails">
                            <div class="inputs">
                                <div class="position-relative input_container mb-16 cursor_pointer"
                                    *ngFor="let item of metadataListTemplateFolder">
                                    <input type="text" readonly placeholder="{{ item.template_name }}" name="" id=""
                                        value="{{ item.template_name }}">
                                    <img *ngIf="currentValue && (currentValue.permissions.manageTemplates)"
                                        class="pencil" src="assets/images/pencil.png" alt=""
                                        (click)="editMetaDataForm(item.uuid,'2')">
                                    <img *ngIf="currentValue && (currentValue.permissions.manageTemplates)"
                                        class="deleteIcon" src="assets/images/delete.png" alt=""
                                        (click)="deleteMetadata(item.uuid)"
                                        [hidden]="item.template_name == 'Default Template'">
                                    <img class="copy" *ngIf="currentValue && (currentValue.permissions.manageTemplates)" alt="" src="assets/images/copy_oprtion.png" (click)="copyTemplate(item.uuid)">
                                </div>
                            </div>

                        </div>
                        <div *ngIf="metadataListTemplateFolder && metadataListTemplateFolder.length == 0" class="text-center mt-5">
                            <img src="assets/images/notemplates.jpg" class="img-fluid mb-2">
                            <p>{{'No Templates Found' | translate}}</p>
                        </div>
                    </div>

                </div>

            </div>

            <div class="modal fade" id="deleteModal3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
                tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal_header">
                            <h5>{{'Delete' | translate}} ?</h5>
                            <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="text-center">{{'Do you want to delete' | translate}}</div>
                        </div>
                        <div id="delete_id" class="btnGrp mt-4">
                            <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal"
                                aria-label="Close">{{'Cancel' | translate}}</button>
                            <button id="button-id" class="btn_primary bg_red" (click)="deleteTemplate()">{{'Delete' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <app-file-preview (sidebarOpened)="onSidebarOpened($event)"></app-file-preview> -->

        </div>

    </div>
</main>
<div class="modal fade" id="addProjectTypeModal" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static"
    aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div id="modal-id" class="modal-dialog modal-dialog-centered">
        <div class="modal-content PriorityModal">
            <div class="modal_header">
                <h5>{{'Add Priority' | translate}}</h5>
                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="closseModal()"></button>
            </div>
            <form [formGroup]="addPrioritytypeForm" (ngSubmit)="onSubmitpriorityType()">
                <div class="modal-body">
                    <div class="input_style">
                        <label for="exampleInputEmail1">{{'Priority' | translate}}</label>
                        <input class="input" type="text" placeholder="{{'Priority' | translate}}"
                            formControlName="priority" maxlength="32">
                    </div>
                    <div *ngIf="projectTypesubmitted && c.priority.errors" class="m-2">

                        <div class="error_message" *ngIf="c.priority.errors.required">{{'Priority' | translate}} {{'is
                            required' | translate}}</div>
                        <div class="error_message" *ngIf="c.priority.errors.pattern">{{'Priority' | translate}} {{'shoud
                            valid' | translate}}</div>

                    </div>
                </div>
                <div class="btnClass">
                    <button type="submit" class="btn_primary bg_blue ">{{'Add' | translate}}</button>
                </div>

            </form>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteModalPriority" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Delete' | translate}} ?</h5>
                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="text-center">{{'Do you want to delete' | translate}}</div>
            </div>
            <div id="delete_id" class="btnGrp mt-4">
                <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal"
                    aria-label="Close">{{'Cancel' | translate}}</button>
                <button id="button-id" class="btn_primary bg_red" (click)="deletePriority()">{{'Delete' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>
<app-sidebar></app-sidebar>

<app-header></app-header>

<main>
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow ">

                <div class="title_container d-flex">
                    <img class="goBack" src="assets/images/angle_left.png" alt="" routerLink="/metadata">
                    <div class="title_lg">{{!uuid ?  ('Create Templates' | translate) : ('Edit Templates' | translate)}}</div>
                </div>
                <div class="template_edit ">
                    <div class="row">
                        <div class="col-md-12 col-xl-10 col-lg-12 ">

                            <form class="inputs" [formGroup]="metedataForm" (ngSubmit)="onSubmitmetaData()">
                                <div class="mt-2">
                                    <div class="add_section metadata_label mt-2">
                                        <label for="">{{'Template Name' | translate}}</label>
                                        <div class="a1 pb-3">
                                            <div class="">

                                                <div class="row">
                                                    <div class="">
                                                        <div class="inArea">
                                                            <div  class="position-relative" >
                                                                <input type="text" AutoSizeInput [extraWidth]="65" formControlName="template_name" placeholder="{{'Template Name' | translate}}" value="Offer Letter"
                                                                    name="" id="inputId" maxlength="32">
                                                            </div>
                                
                                                        </div>
                                                        <div class="" *ngIf="addTemplateSubmit && f.template_name.errors"> 
                                                            <div class="error_message" *ngIf="f.template_name.errors.required">{{'Template Name is required' | translate}}</div>
                                                            <div class="error_message" *ngIf="f.template_name.errors.maxlength">{{'Template Name' | translate}} {{'should not be longer than 32 characters' | translate}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="line metadata_line"></div>
                                        <div class="heading_1 mt-4 mb-1 metadata_heading">
                                            <h4>{{'Template KeyWords' | translate}}</h4>
                                        </div>
                                        <div class="a1" formArrayName="template_details" *ngFor="let item of templates.controls; let i = index">
                                            <div class="" [formGroupName]="i">
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-3 col-sm-12">
                                                        <div class="inArea mt-3">
                                                            <label for="">{{'Keyword Label' | translate}}</label>
                                                           
                                                            <div  class="position-relative">
                                                                <input formControlName="keyword_label" type="text" placeholder="{{'Keyword Label' | translate}}" value="joining date" name="" id="" maxlength="32">
                                                            </div>
                                                            <!-- <div class="" *ngIf="addTemplateSubmit && templates.controls[i].controls.keyword_label.errors"> 
                                                                <div class="error_message" *ngIf="templates.controls[i].controls.keyword_label.errors.required">Keyword Label required</div>
                                                            </div> -->
                                                            <div class="" *ngIf="addTemplateSubmit && templates.controls[i].get('keyword_label').errors"> 
                                                                <div class="error_message" *ngIf="templates.controls[i].get('keyword_label').errors.required">{{'Keyword Label required' | translate}}</div>
                                                            </div>
                                                            <div class="" *ngIf="addTemplateSubmit && metedataForm.get('template_name').errors"> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-3 col-sm-12">
                                                        <div class="inArea mt-3">
                                                            <label for="">{{'Keyword' | translate}}</label>
                                                            <div class="position-relative">
                                                                <input formControlName="keyword" type="text" placeholder="{{'Keyword' | translate}}" value="joining on"
                                                                    name="" id="" maxlength="32">
                                                            </div>
                                                            <!-- <div class="" *ngIf="addTemplateSubmit && templates.controls[i].controls.keyword.errors"> 
                                                                <div class="error_message" *ngIf="templates.controls[i].controls.keyword.errors.required">Keyword required</div>
                                                            </div> -->
                                                            <div class="" *ngIf="addTemplateSubmit && templates.controls[i].get('keyword').errors"> 
                                                                <div class="error_message" *ngIf="templates.controls[i].get('keyword').errors.required">{{'Keyword required' | translate}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-3 col-sm-12">
                                                        <div class="inArea  mt-3">
                                                            <label for="">{{'Field Type' | translate}}</label>
                                                                <mat-select class="position-relative" placeholder="{{'Select icon' | translate}}"
                                                                (selectionChange)="changeClient($event,'a')" formControlName="field_type">
                                                                <!-- <mat-select-trigger *ngIf="selected">
                                                                    <img *ngIf="selected.image && selected.name" src="{{selected.image}}"> {{selected.name}}
                                                                </mat-select-trigger> -->
                                                                    <mat-option *ngFor="let client of options"
                                                                        [value]="client.value">
                                                                        <img src="{{client.image}}"> {{client.name | translate}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            
                                                        <!-- <div class="" *ngIf="addTemplateSubmit && templates.controls[i].controls.field_type.errors"> 
                                                            <div class="error_message" *ngIf="templates.controls[i].controls.field_type.errors.required">Field Type required</div>
                                                        </div> -->
                                                        <div class="" *ngIf="addTemplateSubmit && templates.controls[i].get('field_type').errors"> 
                                                            <div class="error_message" *ngIf="templates.controls[i].get('field_type').errors.required">{{'Field Type required' | translate}}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-3 col-sm-12 inArea1" >

                                                        <div class=" mt-3" *ngIf="metadataType == '1'">
                                                            <label for="">{{'Value Format' | translate}}</label> 
                                                            <div class="position-relative">
                                                                <input  type="text" placeholder="{{'Value Format' | translate}}" 
                                                                    name="" id="" maxlength="32" formControlName="value_format">
                                                            </div>
                                                        </div>
                                                        <img *ngIf="templates.controls.length != 1" [ngClass]="{'delteExtra-class': metadataType !== '1'}" class="deleteIcon" src="assets/images/delete.png" alt="" (click)="deleteTemplate(i)">
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                        </div>

                                        <!-- <div class="a1">
                                            <div class="">
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-4 col-sm-12">
                                                        <div class="inArea mt-3">
                                                            <label for="">{{'Keyword Label' | translate}}</label>
                                                            <div class="position-relative">
                                                                <input type="text" placeholder="Designation" value=""
                                                                    name="" id="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-4 col-sm-12">
                                                        <div class="inArea mt-3">
                                                            <label for="">{{'Keyword' | translate}}</label>
                                                            <div class="position-relative">
                                                                <input type="text" placeholder="for position" value=""
                                                                    name="" id="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-4 col-sm-12">
                                                        <div class="inArea  mt-3">
                                                            <label for="">{{'Field Type' | translate}}</label>
                                                            <mat-select placeholder="{{'Select icon' | translate}}"
                                                            (selectionChange)="changeClient($event,'b')" >


                                                            <mat-select-trigger *ngIf="selected2" >
                                                                <img *ngIf="selected2.image && selected2.name" src="{{selected2.image}}"> {{selected2.name}}
                                                            </mat-select-trigger>



                                                            <mat-option *ngFor="let client of options"
                                                                [value]="client">
                                                                <img src="{{client.image}}"> {{client.name | translate}}
                                                            </mat-option>
                                                        </mat-select>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="a1">
                                        <div class="text-center">
                                            <div class="">

                                                <div class="dragUpload addmetadata mt-4" (click)="addTemplate()">
                                                    <div class="dropArea">
                                                        <div class="a1"><img class="mr-12"
                                                                src="assets/images/zondicons_add-outline.png" alt="">{{'Add Metadata' | translate}}</div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                       <div class="btn_grp mt-5 mb-5">
                                            <button routerLink="/metadata" class="btn_primary bg_grey" >{{'Back' | translate}}</button>
                                            <button type="submit" class="btn_primary bg_blue" >{{'Save Template' | translate}}</button>
                                       </div>
                                    </div>
                                </div>

                            </form>

                        </div>


                    </div>


                </div>

            </div>

        </div>
    </div>
</main>
import { Component, OnInit, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { ApiService } from '../../_services/api.services';
import { Router } from '@angular/router';
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { fromEvent, Subscription,Observable } from 'rxjs';
import { take, filter,map, startWith } from 'rxjs/operators';
import { TemplatePortal } from '@angular/cdk/portal';
import '../../_models/tags-input'; 
import { NgxUiLoaderService } from "ngx-ui-loader";
declare var $: any;
import { FormGroup, FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ModalService } from '../../_services/modal.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

type members = {
  id: number;
  name: string;
  image?: string;
};


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [DatePipe]
})
export class ProjectsComponent implements OnInit {

  // myControl = new FormControl('');
  // options: string[] = ['One', 'Two', 'Three'];
  // options1: string[] = ['One', 'Two', 'Three'];
  // filteredOptions: Observable<string[]>;

  public createForm: FormGroup;

  public addProjectForm: any;
  public addProjectTypeForm:any;
  response:any;
  selectedOption = "";
  selectedOption1 = "";
  gridView = true;
  sub: Subscription;
  showSideBar = false;
  infoSection = true;
  activitySection = false;
  users = [];
  today = new Date();
  today1 = new Date();
  endDate : any;
  startDate :any ;
  @ViewChild('userMenu', {static: false}) userMenu;
  @ViewChild('fileFolderMenu', {static: false}) fileFolderMenu;
  overlayRef: OverlayRef | null;
  private tagInput: any; 
  private tagInput3: any; 
  id : any ;
  projectList: any;
  responselist: any;
  userlist: any;
  viewDetailsData : any;
  viewDetailsList2 : any;
  viewDetailsList : any;
  previewInfo = false;
  responsegroup: any;
  grouplist: any;
  responseProjectTypes: any;
  ProjectTypeslist: any;
  responseListDirectories: any;
  listDirectorieslist: any;
  sharedViewlist: any;
  responseSharedView: any;
  responselistdata: any;
  userlistdataall: any;
  uuidDetails : any ;
  // groupAdded:any;
  groupAdded = new Array();
  projectTypeAdded = new Array();
  usersAdded = new Array();
  projectMembers= new Array();
  submitted = false;
  responseproject: any;
  responseprojectType: any;
  project_Id: any;
  projectResponse: any;
  copyProjectResponse: any;
  projectTypesubmitted = false;
  modalSubmitted = false ;
  access: any;
  shareProfileResponse: any;
  folderSubmitted: boolean;
  public tagInput1: any;
  colors: any;
  limit = 3;
  project: any;
  projectGetlinkresponse: any;
  getlink: any;
  projectMembersuuid:any [] = [];
  currentDate:any;
  todayDate:Date = new Date();
  currentValue: any;
  groupSection = false;
  userSection = true;
  fileList: any;
  folderList: any;
  showCheckBox = false;
  folder_uuid: any;
  filename: any;
  name: any;
  type: any;
  file_Id: any;
  bulkarchieFilesID: any[] = [];
  folder: any;
  delete1 = false;
  restoreLaterResponse: any;
  projectTypeDeleteResponse: any;
  archieveUuid: any;
  prjecTuuid: any;
  modalsubscriptions: any;
  priorityResponse: any;
  priority: any;
  ProjectNAme: any;
  timelineForm: FormGroup;
  todayDatetimeline: any;
  folderDetals: any;
  folderDetails: any;
  timelineResponse: any;
  uuidFolders: any;

  constructor(private api: ApiService,
              private router : Router,
              public overlay: Overlay,
              public viewContainerRef: ViewContainerRef,
              private ngxUiLoaderService: NgxUiLoaderService,
              public formBuilder: FormBuilder,
              public snackBar : MatSnackBar,
              private modalService: ModalService,
              private datePipe: DatePipe) { }

  ngOnInit() {
    // this.getUsers();
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value || '')),
    // );

    // console.log(this.filteredOptions)

    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)

    this.modalsubscriptions = this.modalService.ee.subscribe(data => {
      console.log(data)
      if (data == 'project') {
        this.getProjects();
      }
    });
    this.timelineForm = this.formBuilder.group({
      name:[''],
      folderuuid:[''],
      start_date:[''],
      end_date:['']

    })

    this.createForm = this.formBuilder.group({
      email : ['', [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}")]],
      limit:['',Validators.required]
    });
    this.addProjectForm = this.formBuilder.group({
      project_code : ['',[Validators.required,Validators.pattern("^[0-9]*$")]],
      project_name : ['',[Validators.required]],
      // project_type : ['',[Validators.required]],
      project_description : ['',[Validators.required]],
      project_start_date : ['',[Validators.required]],
      project_end_date : ['',[Validators.required]],
      tag : ['',[Validators.required]]
    });
  
    this.addProjectTypeForm = this.formBuilder.group({
      name : ['',[Validators.required,Validators.pattern("^(?! )[a-zA-ZÀ-ÖØ-öø-ÿ, ]+$")]],
    });

    console.log(this.endDate,"))))")
    this.infoSection = true;
    setTimeout(function () {
      AOS.init({ disable: 'mobile'});
    }, 100);

    this.getProjects();

    this.tagInput1 = new TagsInput({
      selector: 'tag-input1',
      duplicate: false,
      max: 10,
      icon:"assets/images/blue_cross.png"
    });

    
    this.tagInput3 = new TagsInput({
      selector: 'tag-input3',
      duplicate: false,
      max: 10,
      icon:"assets/images/blue_cross.png"
    });

    //this.tagInput.addData(["Financial", "Employees", "Confidential"]);
    this.getGroups();
    this.getUsers()
    this.getProjectType();

    this.startDate = new Date();
    console.log("this.startDate", this.startDate)
    //this.startDate = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    this.startDate = moment(this.startDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
    console.log("this.startDate", this.startDate)

   
    let currentDate = new Date();
   this.endDate = new Date(currentDate);
   this.endDate.setFullYear(currentDate.getFullYear() + 1);
    console.log(this.endDate);
    

    // this.endDate =  new Date(currentDate.getTime() + (24 * 60 * 60 * 1000));
    this.endDate = moment(this.endDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
    console.log("this.endDate", this.endDate)

    this.getPriority()
  }

  // private _filter(value: string): string[] {
  //   console.log("dfdfdsfdsfdsfdsfds", value)
  //   const filterValue = value.toLowerCase();
  //   this.userlist = this.userlistdataall.filter(option => option.name.toLowerCase().includes(filterValue) || option.email.toLowerCase().includes(filterValue));
  //   console.log(this.userlist)
  //   return this.userlist
  // }

  get b() {
    return this.addProjectForm.controls;
  }
  get c() {
    return this.addProjectTypeForm.controls;
  }
  get d(){
    return this.timelineForm.controls;
  }
  get j() {
    return this.createForm.controls;
  }

  toShare(uuid=null,event: MouseEvent = null,projectexceeded =null,checkout_status = null,checkout_access= null) {
    if (event) {
      event.stopPropagation();
    }
    this.close();
    if(projectexceeded == '1'){
      return
    }else{
      var data = {
        entity_type: 2,
        id: [this.project_Id],
        name:this.ProjectNAme
      }
      if (uuid) {
        if(checkout_status == 1 && checkout_access == 0){
          return
        }else{
          data['id'] = [uuid.uuid];
          data['name'] = uuid.name;
        }

        
      }
      console.log(data)

      this.modalService.openModal(data,'project');
    }
    
   
  }
  
  openSideMenu(key, user = null) {
    console.log(user, 'dd')
    if (user) {
      this.uuidDetails = user.uuid
      this.id = user.id
      console.log(this.id);
    }
    switch (key) {
      case 'open':
        console.log(key, 'dd')
        this.showSideBar = true;
        break;
      case 'close':
        console.log('close')
        this.showSideBar = false;
        this.previewInfo = false;
        break;
      case 'previewInfo':
        console.log('previewInfo')
        this.showSideBar = true;
        this.previewInfo = true;
        this.close()
        break;
      case 'uploadPreview':
        console.log('previefsfswInfo');
        setTimeout(() => {
          this.showSideBar = true;
          this.previewInfo = false;
        }, 1000)
        break;
      default:
        this.showSideBar = true;
    }
    if (user){
      console.log(this.id);
      let data = this.id;
      console.log(data);
      
      this.api.getViewDetails(data).subscribe(data => {
        console.log(data,'joooooooooooooooooooooooo');
        this.viewDetailsData = data;
        console.log(this.viewDetailsData);
        if (this.viewDetailsData.status == 200) {
          console.log(this.viewDetailsData);
          this.viewDetailsList = this.viewDetailsData.file_Preview_result.file_list;
          console.log(this.viewDetailsList);
          this.viewDetailsList2 = this.viewDetailsData.file_Preview_result.shared_user;
          console.log(this.viewDetailsList2);
          




        } else {
          this.snackBar.open(this.viewDetailsData.message, "Dismiss", {
            duration: 3000,
          })
        }
      }, error => {
      });
    }
  }
  // toShare(){
  //   $('#shareModal').modal('show');
  //   this.close()
  //   this.getSharedUsers();
  // }

  // getSharedUsers(){
  //   this.createForm.get('email').setValue('')
  //   console.log(this.project_Id);
  //   var data = {
  //     projectId :this.project_Id
  //   }
  //   this.api.getSharedView(data).subscribe(data=>{
  //   this.ngxUiLoaderService.stop();
  //   console.log(data);
  //   this.responseSharedView = data;
  //   console.log(this.responseSharedView);
  //   if (this.responseSharedView.status == 200) {
  //     this.sharedViewlist = this.responseSharedView;
  //     console.log(this.sharedViewlist);
      
  //   }
  // }, error => {
  //   this.ngxUiLoaderService.stop();
  // }); 
  // }
  closseModal(){
    this.resetFormAndModal();
  }

  resetFormAndModal() {
    this.addProjectTypeForm.reset();
    this.projectTypesubmitted = false;
  }
  getValueFromInput() {
    const inputValue = this.tagInput.nativeElement.value;
    console.log('Input value:', inputValue);
  }
  closeModal(){
    this.resetFormAndModal2();
    this.projectMembersuuid = [];
  }

  resetFormAndModal2() {

    this.submitted = false;
    this.folderSubmitted = false;

    this.projectMembers = [];
    this.projectMembersuuid = [];
    this.groupAdded = [];
    this.usersAdded = [];

    this.addProjectForm.reset();

    const tagsWrapper = document.querySelector('.tags-input-wrapper');
    console.log(tagsWrapper);
    
    if (tagsWrapper) {
      const spanTags = tagsWrapper.querySelectorAll('span');
      console.log(spanTags);
      this.projectMembersuuid = [];
      console.log(this.projectMembersuuid);
      
      
      spanTags.forEach(span => {
          span.parentNode.removeChild(span);
      });
    }
    console.log(this.projectMembersuuid);
  }

  show(key) {
    if(key == 'info'){
      this.infoSection = true;
      this.activitySection = false;
    }else {
      this.infoSection = false;
      this.activitySection = true;
    }
  }

  ngOnDestroy() {
    if (this.tagInput) {
      this.tagInput.destroy();
    }
    this.modalsubscriptions.unsubscribe();  
  }

  folderCreate() {
    $('#projectNameAdd').css("display", "none");  
    $('#folderName_And_Tagsection').css("display", "block");
  }
  filter(key:any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if(key == '1') {
        $(".filter").css("display", "block");
        $(".notification_messages").css("display", "none");
    }else {
        $(".filter").css("display", "none");
        $(".notification_messages").css("display", "none");
        $(".profile_settings").css("display", "none");
    }
  }
  getInfo(key:any) {
    console.log(key)
    if(key == '1') {
      this.showSideBar = false;
    }
    else {
      this.showSideBar = true;
    }
    
  }
  getProjects() {
    this.ngxUiLoaderService.start();
    this.api.getProjects().subscribe(data=>{
      
        this.response = data;
        if (this.response.status == 200) {
          this.projectList = this.response.results;
          this.fileList = this.response.files;
          this.folderList = this.response.folder;
          this.ngxUiLoaderService.stop();
          console.log(this.fileList,'11111')
          // this.snackBar.open(this.response.message, "Dismiss", {
          //   duration:3000,
          // })
        }else{
          this.ngxUiLoaderService.stop();
          this.snackBar.open(this.response.message, "Dismiss", {
            duration:3000,
          })
        }
        
    }, error=> {
      this.ngxUiLoaderService.stop();
    });
  } 


  gridActive(key) {
    if(key == "listActive"){
      this.gridView =  false;
    }else {
      this.gridView =  true;
    }
  }

  openProject(uuid,timeline,checkout_status,checkout_access) {
    if(timeline == 1){
      console.log(timeline);
      console.log(uuid);
      this.prjecTuuid = uuid;
      $('#deleteModalforArchieve_open').modal('show');
    }else{
      if(checkout_status == 1 && checkout_access == 0){
        // alert('gsgdsagdvsg')
      }else{
        this.router.navigate(['/folders', uuid])
      }
      
    }
  }

  updateTimeline(){
    $('#deleteModalforArchieve_open').modal('hide');
    $(".modal-backdrop").remove();
    this.router.navigate(['/project-settings', this.prjecTuuid],{ fragment: 'sectionId'})
  }

  opencontext(event: MouseEvent, user) {
    event.preventDefault();
    console.log(user, 'AAAAA');
    this.folderDetals = user;

    if( user.checkout_status == 1 && user.checkout_access == 0){
      return
    }

    this.project_Id = user.uuid;
    this.filename = user.file_name;
    this.name = user.folder_name;
    this.type = user.type;
    this.file_Id = user.uuid;
    this.bulkarchieFilesID.push(this.file_Id)
    this.project = user;
    console.log(this.project);
    if (this.project.file_name) {
      if (this.currentValue.permissions.deleteFile) {
        this.delete1 = true;
      } else {
        this.delete1 = false;
      }
    } else {
      if (this.currentValue.permissions.deleteFolder) { 
        this.delete1 = true;
      } else {
        this.delete1 = false;
      }
    }
    console.log(this.file_Id);
    this.archieveUuid = this.file_Id;
    this.close();
    const isNearBottom = window.innerHeight - (event.clientY + window.scrollY) < 240;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: isNearBottom ? 'bottom' : 'top',
        }
      ]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
    this.overlayRef.attach(new TemplatePortal(this.fileFolderMenu, this.viewContainerRef, {
      $implicit: user
    }));
    this.sub = fromEvent<MouseEvent>(document, 'mousedown')
      .pipe(
        filter(mouseEvent => {
          const clickTarget = mouseEvent.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }



  open(event: MouseEvent, user) {
    // Prevent the default context menu to avoid interference with your custom context menu
    event.preventDefault();
    event.stopPropagation();
    if( user.checkout_status == 1 && user.checkout_access == 0){
      return
    }
  
    console.log(user, 'AAAAA');
    this.project_Id = user.uuid;
    this.ProjectNAme = user.name;
    this.project = user;
    this.close();

    //extra added for bottom breaking
    const isNearBottom = window.innerHeight - (event.clientY + window.scrollY) < 240;

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: isNearBottom ? 'bottom' :'top',
        }
      ]);
  
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
  
    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));
  
    this.sub = fromEvent<MouseEvent>(document, 'mousedown')
      .pipe(
        filter(mouseEvent => {
          const clickTarget = mouseEvent.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }
  delete(user) {
    // delete user
    this.close();
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  createProjectModal() {
    this.submitted = false;
    this.folderSubmitted = false;
    this.projectMembers = [];
    this.groupAdded = [];
    this.usersAdded = [];
    this.addProjectForm.reset();
    console.log(this.startDate);
    // let updateDate = this.convertOwl(this.startDate);
    // let updateEndDate = this.convertOwl(this.endDate);
    // console.log(updateDate,updateEndDate);
    this.addProjectForm.get('project_start_date').setValue(this.startDate);
    this.addProjectForm.get('project_end_date').setValue(this.endDate);
    this.addProjectForm.get('tag').setValue('1');
    $('#createProjectModal').modal('show');
  }

  addProjectTypeModal() {
    $('#addProjectTypeModal').modal('show');
  }
  
  getUsers() {
    this.api.getUsers().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responselist = data;
      if (this.responselist.status == 200) {
        this.userlist = this.responselist.results;
        console.log(this.userlist)
        this.userlistdataall = this.responselist.results;
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 

  getGroups() {
    this.api.getGroups().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responsegroup = data;
      if (this.responsegroup.status == 200) {
        this.grouplist = this.responsegroup.results;
        this.colors = this.grouplist.map(() => this.generateRandomColor());
        console.log(this.colors);
        
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 
  generateRandomColor(): string {
    return '#' + Math.floor(Math.random() * 16777215).toString(16); 
  }

  getShortName(group_name) { 
    return group_name.charAt(0);
    // return group_name.split(' ').map(n => n[0]).join('');
  }

  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }

  onSubmitnewProject() {
    this.submitted = true;
    this.folderSubmitted = true;
    console.log(this.addProjectForm)
    // console.log(this.tagInput1.arr)
    if(this.addProjectForm.invalid ) {
      return
    }
    var data = this.addProjectForm.value;
    // data['user_id'] = this.usersAdded;
    data['user_id'] = this.projectMembersuuid;
    console.log(this.addProjectForm.value.project_start_date)
    console.log(this.addProjectForm.value.project_end_date)
    console.log(moment(this.addProjectForm.value.project_end_date, 'MM/DD/YYYY',true).isValid())
    console.log(Date.parse(this.addProjectForm.value.project_end_date))
    if (moment(this.addProjectForm.value.project_end_date, 'MM/DD/YYYY',true).isValid()) {
      data['project_end_date'] = this.convertOwl(this.addProjectForm.value.project_end_date);
    } 
    if (moment(this.addProjectForm.value.project_start_date, 'MM/DD/YYYY',true).isValid()) {
      data['project_start_date'] = this.convertOwl(this.addProjectForm.value.project_start_date);
    } 
    console.log(data)
    this.ngxUiLoaderService.start();
    this.api.onCreatenewProject(data).subscribe(data=>{
      this.responseproject = data;
      if (this.responseproject.status == 200) {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.responseproject.message, "Dismiss", {
          duration:3000,
        })
        setTimeout(() => {
          $('#createProjectModal').modal('hide');
        },100)
        this.resetFormAndModal2()
        this.getProjects();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.responseproject.message, "Dismiss", {
          duration:3000,
        })
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }


  addGroupMembersList(data) {
    console.log(data);
    console.log(this.groupAdded);
    
    let isDuplicate = this.groupAdded.some(user => user.id === data.id);
    if (!isDuplicate) {
        this.groupAdded.push(data);
        console.log(this.groupAdded, "Added to array");
        let newMember = {
          id: data.uuid,
          name: data.group_name,
          icon: data.group_name || null,
          type : "group",
          color: '#' + Math.floor(Math.random() * 16777215).toString(16)
        };
        this.projectMembers.push(newMember)
        this.projectMembersuuid.push(data.uuid)
        console.log(this.projectMembers)
        console.log(this.projectMembersuuid);
        

    } else {
      console.log('Group is already added to project members.');
    }
  }

  addUsersToList(data) {
    console.log(this.usersAdded);
    
    let isDuplicate = this.usersAdded.some(user => user.id === data.id);
    if (!isDuplicate) {
        this.usersAdded.push(data);
        console.log(this.usersAdded, "Added to array");
        let newMember = {
          id: data.uuid,
          name: data.name,
          image: data.profile_pic || null,
          type : "user"
        };
        this.projectMembers.push(newMember);
        this.projectMembersuuid.push(data.uuid)
        console.log(this.projectMembersuuid);
    } else {
      console.log('User is already added to project members.');
    }
  }

  removeFromMembersList(item) {
    if(item.type == 'user') {
      let isDuplicate = this.usersAdded.filter(user => user.id === item.id);
      if(isDuplicate) {
        let index = this.usersAdded.findIndex(x => x.id === item.id);
        let index2 = this.projectMembers.findIndex(x => x.id === item.id && x.type === "user");
        this.usersAdded.splice(index, 1);
        this.projectMembers.splice(index2, 1);

      }
    }else {
      let isDuplicate = this.groupAdded.filter(user => user.group_id === item.id);
      if(isDuplicate) {
        let index = this.groupAdded.findIndex(x => x.group_id === item.id);
        let index2 = this.projectMembers.findIndex(x => x.id === item.id && x.type === "group");
        this.groupAdded.splice(index, 1);
        this.projectMembers.splice(index2, 1);
      }
    }
  }

  deleteModal(){
    console.log('clicked');
    $('#deleteModal3').modal('show');
    this.close()
  }
  deleteFolderModal(){
    console.log('clicked');
    $('#deleteModal').modal('show');
    this.close()
  }

  deleteProject(){
    var api;
    if (this.project.projectMembers) {
      api = this.api.deleteProject(this.project_Id)
    } else {
      api = this.api.deleteFolderandFile(this.project_Id);
    }
    console.log(api)
    this.ngxUiLoaderService.start();
    api.subscribe(data=>{
      console.log(this.project_Id);
      
      console.log(data,'111');
      
      this.projectResponse = data;
      console.log(this.projectResponse,"2323232323232");
      if(this.projectResponse.status == 200){
        // console.log('file projectd');
        $('#deleteModal3').modal('hide')
        $(".modal-backdrop").remove();
        this.snackBar.open(this.projectResponse.message, "Dismiss", {
          duration:3000,
        })
        this.getProjects();
        this.ngxUiLoaderService.stop();
        
      }else{
        this.snackBar.open(this.projectResponse.message, "Dismiss", {
          duration:3000,
        })
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  onSubmitnewDataType() {
    this.projectTypesubmitted = true;
    console.log(this.addProjectTypeForm)
    if(this.addProjectTypeForm.invalid) {
      return
    }
    var data = this.addProjectTypeForm.value;
    console.log(data)
    this.ngxUiLoaderService.start();
    this.api.onCreatenewProjectType(data).subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responseprojectType = data;
      
      console.log(this.responseprojectType,"jyothiii"); 
      if (this.responseprojectType.status == 200) {
          this.getProjectType();
          this.projectTypesubmitted = false;
        setTimeout(() => {
          $('#addProjectTypeModal').modal('hide');
          this.resetFormAndModal();
        })
      }
      this.snackBar.open(this.responseprojectType.message, "Dismiss", {
        duration:3000,
      })
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getProjectType() {
    this.api.getProjectType().subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responseProjectTypes = data;
      if (this.responseProjectTypes.status == 200) {
        this.ProjectTypeslist = this.responseProjectTypes.result;
        console.log(this.ProjectTypeslist);
        
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 
  listDirectories(){
    this.close();
    this.modalService.openModal(this.project_Id);
    // this.api.getListDirectories().subscribe(data=>{
    //   this.ngxUiLoaderService.stop();
    //   this.responseListDirectories = data;
    //   console.log(this.responseListDirectories);
    //   if (this.responseListDirectories.status == 200) {
    //     this.listDirectorieslist = this.responseListDirectories.results.folders;
    //     console.log(this.listDirectorieslist);
        
    //   }
    // }, error => {
    //   this.ngxUiLoaderService.stop();
    // }); 
  }

  onSubmitModal() {

   this.access = this.createForm.get('limit').value;

   var data =  {
    shared_with:this.tagInput3.arr,
    access:this.access,
    file_id:this.project_Id
    }

   console.log(data);
   this.ngxUiLoaderService.start();
   this.api.shareProjectFileFolder(data).subscribe(data=>{
    console.log(data);
    this.shareProfileResponse = data;
    if(this.shareProfileResponse.status == 200){
      this.ngxUiLoaderService.stop();
      console.log(this.shareProfileResponse);
      this.snackBar.open(this.shareProfileResponse.message, "Dismiss", {
        duration:3000,
      })
    }else{
      this.ngxUiLoaderService.stop();
      this.snackBar.open(this.shareProfileResponse.message, "Dismiss", {
        duration:3000,
      })
    }
   }, error => {
    this.ngxUiLoaderService.stop();
      
    });
   
  }
  
  // getUsers(val) {
  //   console.log(val);
    
  //   if (val == 1) {
  //     this.ngxUiLoaderService.start();
  //   }
  //   this.api.getUsers().subscribe(data=>{
  //     this.ngxUiLoaderService.stop();
  //     this.responselistdata = data;
  //     console.log(this.responselistdata);
      
  //     if (this.responselistdata.status == 200) {
  //       this.userlistdata = this.responselistdata.results;
  //       this.userlistdataall = this.responselistdata.results;
  //       console.log(this.userlistdata);
        
  //     }else{
  //       this.snackBar.open(this.responselistdata.message, "Dismiss", {
  //         duration:3000,
  //       })
  //     }
  //   }, error => {
  //     this.ngxUiLoaderService.stop();
  //   });
  // } 

  onItemAdded(event) {
    console.log("sdsadsadsad",event)
    console.log(this.j.email)
    this.users.push(event.uuid)
    console.log(this.users)
  }
  onItemRemoved(event) {
    console.log("asdsadsadsadasds", event)
    if (this.users.includes(event.uuid)) {
      this.users = this.users.filter(item => item !== event.uuid);
    }
    console.log(this.users)
  }

  rename() {
    this.close();
    var data = {
      entity_type: 3,
      id: this.project_Id,
      data: this.project
    }
    console.log(data);
    this.modalService.openModal(data, 'project');
  }



  getLink(){
    let uuid = this.project_Id;
    console.log(uuid,'clicked');
    this.close();
    let data = {
      uuid:this.project_Id,
      comment : ''
    }
    this.ngxUiLoaderService.start();
    this.api.getProjectLink(data).subscribe(data =>{
      
      console.log(data);
      this.projectGetlinkresponse = data;
      if(this.projectGetlinkresponse.status == 200){
        this.ngxUiLoaderService.stop();

        console.log(this.projectGetlinkresponse);
        this.getlink = this.projectGetlinkresponse.result.link;
        console.log(this.getlink);

        // Create a temporary input element
        var tempInput = $("<input>");
        // Set the input value to the text to be copied
        tempInput.val(this.getlink);
        // Append the input element to the DOM
        $("body").append(tempInput);
        // Select the input's content
        tempInput.select();
        // Execute the copy command
        document.execCommand("copy");
        // Remove the temporary input element
        tempInput.remove();
        this.snackBar.open(this.projectGetlinkresponse.message, "Dismiss", {
          duration:3000,
        })
      }
      else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.projectGetlinkresponse.message, "Dismiss", {
          duration:3000,
        })
      }
      
    }, error => {
          this.ngxUiLoaderService.stop();
        });

  }

  copyProject(){
    console.log('clicked');
    this.close();
    var data = { file_id: this.project_Id }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.copyProjectFile(data).subscribe(data => {
      console.log(data);
      this.copyProjectResponse = data;
      if (this.copyProjectResponse.status == 200) {
        console.log(this.copyProjectResponse);
        this.getProjects();
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.copyProjectResponse.message, "Dismiss", {
          duration: 3000,
        })
        
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.copyProjectResponse.message, "Dismiss", {
          duration: 3000,
        })
      }

    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  clickTab(key) {
    if(key == 'user'){
      this.userSection = true;
      this.groupSection = false;
    }else {
      this.userSection = false;
      this.groupSection = true;
    }
  }

  bulkDelete(val, data) {

  }
  openFolder(uuid,timeline,data,checkout_status,checkout_access) {
    this.folderDetails = data;
    this.uuidFolders = uuid;
    if(timeline == 1){
      $('#deleteModalforArchieve_open').modal('show');
    }else{
      if(checkout_status == 1 && checkout_access == 0){
        // alert('gsgdsagdvsg')
      }else{
        this.router.navigate(['/folders', uuid]);
      }
      
    }
    
  }
  getImagePreview(val) {
      var data = {
        entity_type: 4,
        data: val,
        list: this.fileList
      }
      this.modalService.openModal(data);
  }

  moveProjectsFileorFolder() {
    this.close();
    var data = {
      entity_type: 1,
      id: this.project_Id
    }
    this.modalService.openModal(data, 'project');
  }

  restoreLater(){

    this.close();

    if(this.prjecTuuid){
      this.project_Id = this.prjecTuuid;
      console.log('qewewqewe');
      
    }else{
      this.project_Id = this.archieveUuid;
      console.log('sdsadd',this.archieveUuid);
      
    }
    var data = {
      files: [this.project_Id],
      entity_type: 6
    }
    this.modalService.openModal(data, 'project');
    $('#deleteModalforArchieve_project').modal('hide');
    console.log(data,"clicked");
    // this.closebutton.nativeElement.click();
    // this.ngxUiLoaderService.start();
    // this.api.bulkDeletePermenently(data).subscribe(data=>{
    // this.restoreLaterResponse = data;
    // console.log(this.restoreLaterResponse);
    
    // if(this.restoreLaterResponse.status == 200){
    //   this.ngxUiLoaderService.stop();
    //   this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //     duration:3000,
    //   })
    //   $('#deleteModalforArchieve_project').modal('hide');
    //   $('#deleteModalforArchieve_open').modal('hide');
    //   this.prjecTuuid = null;
    //   this.getProjects();
      
    // }else{
    //   this.ngxUiLoaderService.stop();
    //   this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //     duration:3000,
    //   })
      
    // }
     
    // },error=> {
    //   this.ngxUiLoaderService.stop();
    // });
    

  }

  openArchieveModal(data=null){

    if(data == 'folder_timeline'){
      console.log('huhuh');
      this.project_Id = this.uuidFolders;
    }
    console.log('clicked',this.project_Id);
    this.archieveUuid = this.project_Id;
    $('#deleteModalforArchieve_open').modal('hide');
    $(".modal-backdrop").remove();
    setTimeout(() => {
      $('#deleteModalforArchieve_project').modal('show');
    }, 100);
    
    this.close()
  }

  deleteProjectType(val){
    console.log(val);
    let data = {
      project_type : val,
    }
    this.ngxUiLoaderService.start();
    this.api.deleteProjecttype(data).subscribe(data=>{
      console.log(data);
      this.projectTypeDeleteResponse = data;
      if(this.projectTypeDeleteResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.getProjectType();
        $('#createProjectModal').modal('show');
        this.snackBar.open(this.projectTypeDeleteResponse.message, "Dismiss", {
          duration:3000,
        })
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.projectTypeDeleteResponse.message, "Dismiss", {
          duration:3000,
        })
      }
    })
  }

  updateEndDate() {
    if (new Date(this.b.project_end_date.value) < new Date(this.b.project_start_date.value)) {
      this.addProjectForm.get('project_end_date').setValue(this.b.project_start_date.value);
    }
  }
  getPriority(){
    this.ngxUiLoaderService.start();
    this.api.getPrioritydata().subscribe(data=>{
      console.log(data);
      this.priorityResponse = data;
      if(this.priorityResponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.priorityResponse);
        this.priority = this.priorityResponse.result;
        
      }else{
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }
  checkoutModal(value = null){
    this.close();
    let data:any = {
      entity_type: 7,
      id: this.project_Id,
      name:this.ProjectNAme
    }
    if(value ){
      data.checkin = '2'
    }
    console.log(data)
    this.modalService.openModal(data,'project');
  }

  updateFolderTimeline(edit=null){
    $('#deleteModalforArchieve_open').modal('hide');
    console.log(this.folderDetails);
    
    // if (edit == '1') {
    //   this.folderdetailsdata = this.folder;
    // }

    console.log(this.folderDetails);
    console.log(this.folderDetails.start_date);
    this.todayDatetimeline = this.folderDetails.start_date;

    this.timelineForm.get('name').setValue(this.folderDetails.name);
    this.timelineForm.get('folderuuid').setValue(this.folderDetails.uuid);
    this.timelineForm.get('start_date').setValue(this.folderDetails.start_date);
    this.timelineForm.get('end_date').setValue(this.folderDetails.end_date);
    $('#folderEdit_timlinemodal').modal('show');
    
  }
  // convertOwl(str) {
  //   if(str == null)
  //         return str;
  //     var mnths = {
  //       Jan: "01",
  //       Feb: "02",
  //       Mar: "03",
  //       Apr: "04",
  //       May: "05",
  //       Jun: "06",
  //       Jul: "07",
  //       Aug: "08",
  //       Sep: "09",
  //       Oct: "10",
  //       Nov: "11",
  //       Dec: "12"
  //     },
      
  //     date = str.toString().split(" ");
  //     if(date.length == 1)
  //       return str;
  //     else
  //       return [date[3], mnths[date[1]], date[2]].join("-");
  //   }

  updateTimelinefolder(){
    let value= this.timelineForm.value;
    console.log(moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid())
    console.log(Date.parse(this.timelineForm.value.end_date))
    if (moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid()) {
      value['end_date'] = this.convertOwl(this.timelineForm.value.end_date);
    } 
    if (moment(this.timelineForm.value.start_date, 'MM/DD/YYYY',true).isValid()) {
      value['start_date'] = this.convertOwl(this.timelineForm.value.start_date);
    } 
    console.log( this.timelineForm.value);

    let data = this.timelineForm.value;
    this.ngxUiLoaderService.start();
    this.api.updateFolderTimeline(data).subscribe(data=>{
      console.log(data);
      this.timelineResponse = data;
      if(this.timelineResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
        $('#folderEdit_timlinemodal').modal('hide');

        this.getProjects();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
    
  }
  closeTimelinemodal(){
    $('#folderEdit_modal').modal('hide');
  }


}


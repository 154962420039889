<app-sidebar></app-sidebar>
<app-header></app-header>
<main>
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">
                <form [formGroup]="settingsForm" >
                    <div class="title_container mb-4">
                        <div class="parent-flex">
                            <div class="title_left">
                                <div class="d-flex align-items-center">
                                    <img src="assets/images/angle_left.png" class="cursor_pointer angle_left_icon"
                                        alt="" (click)="goBack()">
                                    <div class="title_lg">{{'Project Settings' | translate}}</div>
                                    <!-- <div class="settingsBtn cursor_pointer" routerLink="/project-settings"><img src="assets/images/blueWheel.png" alt="" class="mr-2">Settings</div> -->
                                </div>
                                <div class="d-flex"></div>
                            </div>
                            <div class="endChild">
                                <button class="btn_primary" (click)="clickCancel()">{{'Cancel' | translate}}</button>
                                <button class="btn_primary bg_blue" (click)="onSubmitProjectSettings()">{{'Save changes' |
                                    translate}}</button>
                            </div>
                        </div>
                        <ul class="breadcrumb">
                            <li>{{name}}</li>
                            <li><img src="assets/images/arrow_right_sm.png" alt=""> <span>{{'Project Settings' |
                                    translate}}</span></li>
                        </ul>
                    </div>


                    <div class="inputs_container" *ngIf="proJectDetails">
                        <div class="container_input_flex">
                            <div class="input_style">
                                <label for="exampleInputEmail1">{{'Project name' | translate}}</label>
                                <input class="input" type="email" aria-describedby="emailHelp" placeholder="Full Name"
                                     formControlName="project_name" maxlength="32">

                                <div *ngIf="submitted && b.project_name.errors" class="m-2">
                                    <div class="error_message" *ngIf="b.project_name.errors.required">{{'Project name' | translate}} {{'is required' | translate}}</div>
                                    <div class="error_message" *ngIf="b.project_name.errors.pattern">{{'Project name' | translate}} {{'shoud valid' | translate}}</div>
                                    <div class="error_message" *ngIf="b.project_name.errors.maxlength">{{'Project name' | translate}} {{'should not be longer than 32 characters' | translate}}</div>
                                </div>
                            </div>
                            <!-- <div class="sort_parent input_style">
                                <label for="exampleInputEmail1">{{'Project type' | translate}}</label>
                                <mat-form-field class="custom-form-field1" appearance="none">
                                    <mat-select formControlName="project_type" >
                                        <mat-option *ngFor="let list of ProjectTypeslist"   [value]="list.id">{{ list.name | translate }}</mat-option>
                                    </mat-select>
                                </mat-form-field>  
                                <div *ngIf="submitted && b.project_type.errors" class="m-2">
                                    <div class="error_message" *ngIf="b.project_type.errors.required">{{'Project type' | translate}} {{'is required' | translate}}</div>
                                    <div class="error_message" *ngIf="b.project_type.errors.pattern">{{'Project type' | translate}} {{'shoud valid' | translate}}</div>
                                </div> 
                            </div> -->
                        </div>
                        <div class="input_style mt-3">
                            <label for="exampleInputEmail1">{{'Project Code' | translate}}</label>
                            <input class="input" type="tel" aria-describedby="emailHelp" placeholder="Project Code"
                                value="{{proJectDetails.code}}"  formControlName="project_code" readonly>
                            <div *ngIf="submitted && b.project_code.errors" class="m-2">
                                <div class="error_message" *ngIf="b.project_code.errors.required">{{'Project Code' | translate}} {{'is required' | translate}}</div>
                                <div class="error_message" *ngIf="b.project_code.errors.pattern">{{'Project Code' | translate}} {{'shoud valid' | translate}}</div>
                            </div>
                        </div>

                        <div class="input_style mt-3">
                            <label for="exampleInputEmail1">{{'Project Description' | translate}}</label>
                            <textarea name="" class="" id="" cols="30"
                                rows="5" value="{{proJectDetails.project_description}}" formControlName="project_description"></textarea>
                            <div *ngIf="submitted && b.project_description.errors" class="m-2">
                                <div class="error_message" *ngIf="b.project_description.errors.required">{{'Project Description' | translate}} {{'is required' | translate}}</div>
                            </div>
                        </div>

                        <div class="input_style mt-3">
                            <label for="exampleInputEmail1">{{'Priority' | translate}}</label>

                            
                                <mat-form-field class="custom-form-field1" appearance="none">
                                    <mat-select placeholder="{{'Select Priority' | translate}}"   formControlName="tags">
                                        <mat-option value="1"  >{{ 'High'| translate }}</mat-option>
                                        <mat-option value="2">{{'Medium' | translate}}</mat-option>
                                        <mat-option value="3">{{'Low' | translate}}</mat-option>
                                        <mat-option value="{{item.id}}"  *ngFor="let item of priority">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field> 
                            
                            <div *ngIf="submitted && b.tags.errors" class="m-2">
                                <div class="error_message" *ngIf="b.tags.errors.required">{{'Priority' | translate}} {{'is required' | translate}}</div>
                            </div>

                        </div>
                     
                    

                        <div class="title_container mb-1 mt-5 mb-3" id="sectionId" #sectionId>
                            <div class="parent-flex">
                                <div class="title_left">
                                    <div class="d-flex align-items-center">
                                        <div class="title_lg2">{{'Project timeline' | translate}}</div>
                                    </div>
                                    <div class="d-flex"></div>
                                </div>
                            </div>
                        </div>

                        <div class="container_input_flex" >
                            <div class="input_style d-flex align-items-center"  >
                                <div class="timeLine">
                                    <label for="">{{'Starts from' | translate}}</label>
                                    <mat-form-field class="example-full-width" appearance="none">
                                        <input matInput [matDatepicker]="dp1" [min]="todayDate" [matDatepicker]="picker" readonly  formControlName="project_start_date" (dateChange)="updateEndDate()">
                                        <mat-datepicker-toggle matIconSuffix [for]="dp1"></mat-datepicker-toggle>
                                        <mat-datepicker #dp1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <img class="minus" src="assets/images/minus.png" alt="">
                                <div class="timeLine">
                                    <label for="">{{'Ends on' | translate}}</label>
                                    <mat-form-field class="example-full-width" appearance="none">
                                        <input matInput [matDatepicker]="dp2" [min]="b.project_start_date.value" [matDatepicker]="picker" readonly  formControlName="project_end_date">
                                        <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                                        <mat-datepicker #dp2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="title_container mb-1 mt-5">
                            <div class="parent-flex">
                                <div class="title_left">
                                    <div class="d-flex align-items-center">
                                        <div class="title_lg2">{{'Data Details' | translate}}</div>
                                    </div>
                                    <div class="d-flex"></div>
                                </div>
                            </div>
                        </div>
                        <div id="overView">
                            <div class="hFlex mt-3" id="" >
                                <div class="card1" *ngFor="let data of proJectDatadetailsData">
                                    <div class="d-flex align-items-center" >
                                        <img class="mr-12" src="{{data.image}}" alt="">
                                        <div>
                                            <div class="subText">{{ data.name | translate}}</div>
                                            <div class="smText">{{data.no_of_file}} {{'Files' | translate}}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="title_container mb-4 mt-5">
                            <div class="parent-flex">
                                <div class="title_left">
                                    <div class="d-flex align-items-center">
                                        <div class="title_lg2">{{'Project Teams' | translate}}</div>
                                        <!-- <div class="settingsBtn cursor_pointer" routerLink="/project-settings"><img src="assets/images/blueWheel.png" alt="" class="mr-2">Settings</div> -->
                                    </div>
                                    <div class="d-flex"></div>
                                </div>
                                <div class="endChild">
                                    <button class="btn_primary bg_blue" (click)="openAddGroupModal()">{{'Add Group / User' | translate}}</button>
                                </div>
                            </div>
                        </div>

                        <div class="wrapper candidateTable">
                            <div class="Rtable Rtable--5cols Rtable--collapse" id="" >
                                <div class="Rtable-row Rtable-row--head">
                                    <div class="Rtable-cell name_cell column-heading">{{'Name' | translate}}</div>
                                    <div class="Rtable-cell date-cell column-heading">{{'Date Added' | translate}}</div>
                                    <div class="Rtable-cell time-cell column-heading">{{'Department' | translate}}</div>
                                    <div class="Rtable-cell permission-cell column-heading">Permissions</div>
                                    <div class="Rtable-cell action-cell column-heading">{{'Actions' | translate}}</div>
                                </div>

                                <div class="is-striped cursor_pointer contents w-100">

                                    <div [ngClass]="{'activeColor': teamsMembers == true}" *ngFor="let data of groupList index as i">
                                        <div class="Rtable-row is-striped">
                                        <div class="Rtable-cell name_cell">
                                            <div class="Rtable-cell--content name-content">
                                                <!-- <img class="mr-12" src="assets/images/group.png" alt=""> -->
                                                <div class="groupIconMembersList2" [style.background-color]="colorss[i]">{{getShortName(data.group_name)}}</div>
                                                <div class="groupName2">
                                                <span>{{data.group_name}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="Rtable-cell date-cell">
                                            <div class="Rtable-cell--heading">Date Added</div>
                                            <div class="Rtable-cell--content replay-link-content">{{data.last_modified}}</div>
                                        </div>
                                        <div class="Rtable-cell time-cell">
                                            <div class="Rtable-cell--heading">Department</div>
                                            <div class="Rtable-cell--content date-content">
                                                <ul class="mb-0 ul_line mr-12" >
                                                   --
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="Rtable-cell Rtable-cell--foot permission-cell">
                                            <!-- <div class="Rtable-cell--heading">Permissions</div>
                                                <div class="Rtable-cell--content shared-content"> 
                                                    <mat-form-field class="permissionSelector" appearance="none">
                                                        <mat-select [(ngModel)]="selectedOption">
                                                        <mat-option value="">{{'filter' | translate}}</mat-option>
                                                        <mat-option value="2">{{'filter' | translate}}</mat-option>
                                                        <mat-option value="green">{{'filter' | translate}}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div> -->
                                        </div>
                                        <div class="Rtable-cell action-cell">
                                            <div class="Rtable-cell--heading">Actions</div>
                                                <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1="" alt="" class="mr-12" src="assets/images/red_bin.png" (click)="deleteUserList(data.uuid)">
                                                    <img _ngcontent-oee-c1="" alt="" class="mr-12 rotateIcon" id="member{{i}}"
                                                    src="assets/images/arrow_grey.png" (click)="showTeams(i)">
                                                </div>
                                        </div>
                                    </div>
                                        <div class="subSection d-one" [ngClass]="{'activeColor': teamsMembers == true}" id="members{{i}}">
                                            <div class="Rtable-row subSection" *ngFor="let item of data.groupMembers">
                                                <div class="Rtable-cell name_cell">
                                                    <div class="Rtable-cell--content name-content">
                                                        <img class="mr-12 GroupGroupMembers" src="{{item.profile_url}}" alt="">
                                                        <div class="userdetails">
                                                            <span class="firstChild" >{{item.name}}</span>
                                                            <span>{{item.email}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="Rtable-cell date-cell">
                                                    <!-- <div class="Rtable-cell--heading">Date Added</div>
                                                        <div class="Rtable-cell--content replay-link-content">January 10, 2023</div> -->
                                                </div>
                                                <div class="Rtable-cell time-cell">
                                                    <!-- <div class="Rtable-cell--heading">Timeline</div>
                                                        <div class="Rtable-cell--content date-content"><span class="">Feb 10, 2023-April 10, 2023</span></div> -->
                                                </div>

                                                <div class="Rtable-cell Rtable-cell--foot permission-cell">
                                                    <!-- <div class="Rtable-cell--heading">Permissions</div>
                                                    <div class="Rtable-cell--content shared-content">
                                                        <mat-form-field class="permissionSelector" appearance="none">
                                                            <mat-select [(ngModel)]="selectedOption"
                                                                [ngModelOptions]="{standalone: true}">
                                                                <mat-option value="">filter</mat-option>
                                                                <mat-option value="2">filter</mat-option>
                                                                <mat-option value="green">filter</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div> -->
                                                </div>
                                                <div class="Rtable-cell action-cell">
                                                    <!-- <div class="Rtable-cell--heading">Actions</div>
                                                        <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1="" alt="" class="mr-12" src="assets/images/red_bin.png"></div> -->
                                                </div>

                                            </div>
                                    </div>
                                    </div>

                                </div>


                                <div id="contents" class="Rtable-row is-striped cursor_pointer contents" *ngFor="let data of projectData.users">
                                    <div class="Rtable-cell name_cell" >
                                        <div class="Rtable-cell--heading">Name</div>
                                        <div class="Rtable-cell--content name-content userdetAils">
                                            <img class="mr-12 userPropic" src="{{data.profile_pic}}" alt="">
                                            <div>
                                                <span class="firstChild">{{data.name}}</span>
                                                <span>{{data.email}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell date-cell">
                                        <div class="Rtable-cell--heading">Date Added</div>
                                        <div class="Rtable-cell--content replay-link-content">{{data.date_added}}</div>
                                    </div>
                                    <div class="Rtable-cell time-cell">
                                        <div class="Rtable-cell--heading">Timeline</div>
                                        <div class="Rtable-cell--content date-content"><span class="">{{data.description}}</span></div>
                                    </div>

                                    <div class="Rtable-cell Rtable-cell--foot permission-cell">
                                        <div class="Rtable-cell--heading">Permissions</div>
                                        <div class="Rtable-cell--content shared-content">
                                            <mat-form-field class="permissionSelector" appearance="none">
                                                <mat-select [(ngModel)]="selectedOption"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <mat-option value="">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="2">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="green">{{'filter' | translate}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell action-cell">
                                        <div class="Rtable-cell--heading">Actions</div>
                                        <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1=""
                                                alt="" class="mr-12" src="assets/images/red_bin.png" (click)="deleteUserList(data)"></div>
                                    </div>
                                    
                                </div>

                                <div class="text-center mt-5 w-100" *ngIf="projectData.users && projectData.users.length == 0 && groupList && groupList.length == 0">
                                    <img src="assets/images/nousers.jpg" class="img-fluid mb-2">
                                    <div id="error_messages" >{{'There is no users or groups added against your Project' | translate}} <br> {{'Please add users and groups by clicking Add group / User' | translate}}</div>
                                </div>
                                <!-- <div class="Rtable-row is-striped cursor_pointer contents">
                                    <div class="Rtable-cell name_cell">
                                        <div class="Rtable-cell--heading">Date</div>
                                        <div class="Rtable-cell--content name-content">
                                            <img class="mr-12" src="assets/images/user1.png" alt="">
                                            <div>
                                                JohnWick
                                                <span>johnwick098@gmail.com</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell date-cell">
                                        <div class="Rtable-cell--heading">Date Added</div>
                                        <div class="Rtable-cell--content replay-link-content">January 10, 2023</div>
                                    </div>
                                    <div class="Rtable-cell time-cell">
                                        <div class="Rtable-cell--heading">Timeline</div>
                                        <div class="Rtable-cell--content date-content"><span class="">Feb 10, 2023-April
                                                10, 2023</span></div>
                                    </div>

                                    <div class="Rtable-cell Rtable-cell--foot permission-cell">
                                        <div class="Rtable-cell--heading">Permissions</div>
                                        <div class="Rtable-cell--content shared-content">
                                            <mat-form-field class="permissionSelector" appearance="none">
                                                <mat-select [(ngModel)]="selectedOption"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <mat-option value="">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="2">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="green">{{'filter' | translate}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell action-cell">
                                        <div class="Rtable-cell--heading">Actions</div>
                                        <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1=""
                                                alt="" class="mr-12" src="assets/images/red_bin.png"></div>
                                    </div>
                                </div> -->
                                <!-- <div class="Rtable-row is-striped cursor_pointer contents">
                                    <div class="Rtable-cell name_cell">
                                        <div class="Rtable-cell--heading">Date</div>
                                        <div class="Rtable-cell--content name-content">
                                            <img class="mr-12" src="assets/images/user1.png" alt="">
                                            <div>
                                                JohnWick
                                                <span>johnwick098@gmail.com</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell date-cell">
                                        <div class="Rtable-cell--heading">Date Added</div>
                                        <div class="Rtable-cell--content replay-link-content">January 10, 2023</div>
                                    </div>
                                    <div class="Rtable-cell time-cell">
                                        <div class="Rtable-cell--heading">Timeline</div>
                                        <div class="Rtable-cell--content date-content"><span class="">Feb 10, 2023-April
                                                10, 2023</span></div>
                                    </div>

                                    <div class="Rtable-cell Rtable-cell--foot permission-cell">
                                        <div class="Rtable-cell--heading">Permissions</div>
                                        <div class="Rtable-cell--content shared-content">
                                            <mat-form-field class="permissionSelector" appearance="none">
                                                <mat-select [(ngModel)]="selectedOption"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <mat-option value="">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="2">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="green">{{'filter' | translate}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell action-cell">
                                        <div class="Rtable-cell--heading">Actions</div>
                                        <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1=""
                                                alt="" class="mr-12" src="assets/images/red_bin.png"></div>
                                    </div>
                                </div> -->
                                <!-- <div class="Rtable-row is-striped cursor_pointer contents">
                                    <div class="Rtable-cell name_cell">
                                        <div class="Rtable-cell--heading">Date</div>
                                        <div class="Rtable-cell--content name-content">
                                            <img class="mr-12" src="assets/images/user1.png" alt="">
                                            <div>
                                                JohnWick
                                                <span>johnwick098@gmail.com</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell date-cell">
                                        <div class="Rtable-cell--heading">Date Added</div>
                                        <div class="Rtable-cell--content replay-link-content">January 10, 2023</div>
                                    </div>
                                    <div class="Rtable-cell time-cell">
                                        <div class="Rtable-cell--heading">Timeline</div>
                                        <div class="Rtable-cell--content date-content"><span class="">Feb 10, 2023-April
                                                10, 2023</span></div>
                                    </div>

                                    <div class="Rtable-cell Rtable-cell--foot permission-cell">
                                        <div class="Rtable-cell--heading">Permissions</div>
                                        <div class="Rtable-cell--content shared-content">
                                            <mat-form-field class="permissionSelector" appearance="none">
                                                <mat-select [(ngModel)]="selectedOption"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <mat-option value="">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="2">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="green">{{'filter' | translate}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell action-cell">
                                        <div class="Rtable-cell--heading">Actions</div>
                                        <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1=""
                                                alt="" class="mr-12" src="assets/images/red_bin.png"></div>
                                    </div>
                                </div> -->
                                <!-- <div class="Rtable-row is-striped cursor_pointer contents">
                                    <div class="Rtable-cell name_cell">
                                        <div class="Rtable-cell--heading">Date</div>
                                        <div class="Rtable-cell--content name-content">
                                            <img class="mr-12" src="assets/images/user1.png" alt="">
                                            <div>
                                                JohnWick
                                                <span>johnwick098@gmail.com</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell date-cell">
                                        <div class="Rtable-cell--heading">Date Added</div>
                                        <div class="Rtable-cell--content replay-link-content">January 10, 2023</div>
                                    </div>
                                    <div class="Rtable-cell time-cell">
                                        <div class="Rtable-cell--heading">Timeline</div>
                                        <div class="Rtable-cell--content date-content"><span class="">Feb 10, 2023-April
                                                10, 2023</span></div>
                                    </div>

                                    <div class="Rtable-cell Rtable-cell--foot permission-cell">
                                        <div class="Rtable-cell--heading">Permissions</div>
                                        <div class="Rtable-cell--content shared-content">
                                            <mat-form-field class="permissionSelector" appearance="none">
                                                <mat-select [(ngModel)]="selectedOption"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <mat-option value="">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="2">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="green">{{'filter' | translate}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell action-cell">
                                        <div class="Rtable-cell--heading">Actions</div>
                                        <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1=""
                                                alt="" class="mr-12" src="assets/images/red_bin.png"></div>
                                    </div>
                                </div> -->
                                <!-- <div class="Rtable-row is-striped cursor_pointer contents">
                                    <div class="Rtable-cell name_cell">
                                        <div class="Rtable-cell--heading">Date</div>
                                        <div class="Rtable-cell--content name-content">
                                            <img class="mr-12" src="assets/images/user1.png" alt="">
                                            <div>
                                                JohnWick
                                                <span>johnwick098@gmail.com</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell date-cell">
                                        <div class="Rtable-cell--heading">Date Added</div>
                                        <div class="Rtable-cell--content replay-link-content">January 10, 2023</div>
                                    </div>
                                    <div class="Rtable-cell time-cell">
                                        <div class="Rtable-cell--heading">Timeline</div>
                                        <div class="Rtable-cell--content date-content"><span class="">Feb 10, 2023-April
                                                10, 2023</span></div>
                                    </div>

                                    <div class="Rtable-cell Rtable-cell--foot permission-cell">
                                        <div class="Rtable-cell--heading">Permissions</div>
                                        <div class="Rtable-cell--content shared-content">
                                            <mat-form-field class="permissionSelector" appearance="none">
                                                <mat-select [(ngModel)]="selectedOption"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <mat-option value="">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="2">{{'filter' | translate}}</mat-option>
                                                    <mat-option value="green">{{'filter' | translate}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="Rtable-cell action-cell">
                                        <div class="Rtable-cell--heading">Actions</div>
                                        <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1=""
                                                alt="" class="mr-12" src="assets/images/red_bin.png"></div>
                                    </div>
                                </div> -->
                                

                            </div>
                           
                        </div>
                    </div>

                </form>

                <div class="modal fade" id="addUserModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title exampleModalToggleLabel" id="exampleModalToggleLabel">{{'Add Group / User' | translate}}
                                </h5>
                                <button type="button" class="btn-close"  (click)="closeAdduserProjectmodal()"></button>
                            </div>
                            <div class="modal-body">

                                <div class="title">{{'Project Members' | translate}}</div>

                                <div class="membersList">
                                    <div class="members_container">
                                        <div class="members" *ngFor="let member of grouMembers; let i = index">

                                            <div *ngIf="member.profile_pic" > <img class="membersPic" src="{{member.profile_pic}}" alt=""> {{member.name}}</div>
                                            <div *ngIf="!member.profile_pic" class="d-flex"><div class="groupIconMembersList" [style.background-color]="colors[i]">{{getShortName(member.group_name)}}</div>
                                            <div class="groupNameMembersList">{{member.group_name}}</div> </div>


                                            <!-- <div><img class="userImageadd" src={{member.profile_pic}} alt="">{{member.name}} {{member.group_name}}</div> -->
                                            <img class="clossIcon" src="assets/images/close_sm.png" alt="" (click)="removeUser(member,i, 1)">
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="submittedGroup && grouMembersuuid.length == 0" class="m-2">
                                    <div class="error_message" >{{'Project Members' | translate}} {{'is required' | translate}}</div>
                                </div>
                                

                                <div class="title">{{'Add Groups' | translate}}</div>
                                <div class="addUserList">
                                    <div class="groups">
                                        <div class="list" *ngFor="let groups of grouplist index as i">
                                            <div class="logoArea" >
                                                <div class="s-name mr-12" class="groupIcon" [style.background-color]="colors[i]">
                                                    {{getShortName(groups.group_name)}}
                                                </div>
                                                <!-- <img class="" src="assets/images/group1.png" alt=""> -->
                                                <div class="groupNAme">{{groups.group_name}} <span *ngFor=" let members of groups.groupMembers">{{members.length}}</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" (click)="addGroup(groups)">{{'Add' | translate}}</button>
                                        </div>
                                    </div>     
                                </div>

                              

                                <div class="title">{{'Add Users' | translate}}</div>

                                <div class="addUserList">
                                    <div class="groups">
                                        <div class="list" *ngFor="let user of activeUserlist">
                                            <div class="logoArea">
                                                <img class="imageUserlist" src="{{user.profile_pic}}" alt="">
                                                <div>{{user.name}} <span>{{user.email}}</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" (click)="addGroup(user)">{{'Add' | translate}}</button>
                                        </div>
                                        <!-- <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/dev2.png" alt="">
                                                <div>James bellward <span>jamesbell@gmail.com</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" 
                                                >{{'Add' | translate}}</button>
                                        </div>
                                        <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/dev3.png" alt="">
                                                <div>Casper kay <span>casperkay@gmail.com</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" 
                                                >{{'Add' | translate}}</button>
                                        </div>
                                        <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/dev1.png" alt="">
                                                <div>Mars kony <span>marskony@gmail.com</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue"
                                                >{{'Add' | translate}}</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button class="btn_primary bg_blue"  (click)="addGroupMembers()">{{ 'Save' | translate }}</button>
                            </div>
                        </div>

                    </div>


                </div>


            </div>
        </div>
    </div>

    <div class="modal fade" id="deleteModal3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Delete' | translate}} ?</h5>
              <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="text-center">{{'Do you want to delete' | translate}}</div>
            </div>
            <div id="delete_id" class="btnGrp mt-4">
              <button class="btn_primary bg_blue" id="button-id" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
              <button id="button-id" class="btn_primary bg_red"  (click)="deleteUser()">{{'Delete' | translate}}</button>
            </div>      
          </div>
        </div>
      </div>
</main>
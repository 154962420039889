import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FavouriteComponent } from './pages/favourite/favourite.component';
import { HeaderComponent } from './pages/layout/header/header.component';
import { SidebarComponent } from './pages/layout/sidebar/sidebar.component';
import { SharedComponent } from './pages/shared/shared.component';
import { RecyclebinComponent } from './pages/recyclebin/recyclebin.component';
import { LoginComponent } from './pages/register/login/login.component';
import { ForgotPasswordComponent } from './pages/register/forgot-password/forgot-password.component';
import { FooterComponent } from './pages/layout/footer/footer.component';
import { SignupComponent } from './pages/register/signup/signup.component';
import { AuthGuard } from './_helpers/auth.guard';

import { PackagesComponent } from './pages/register/packages/packages.component';
import { BusinessInfoComponent } from './pages/register/business-info/business-info.component';
import { ContactInfoComponent } from './pages/register/contact-info/contact-info.component';
import { BusinessCreationComponent } from './pages/register/business-creation/business-creation.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { CatalogueComponent } from './pages/catalogue/catalogue.component';
import { SubscriptionPlanComponent } from './pages/subscription-plan/subscription-plan.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { FoldersComponent } from './pages/folders/folders.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { DomainPermissionComponent } from './pages/register/domain-permission/domain-permission.component';
import { DomainSearchComponent } from './pages/register/domain-search/domain-search.component';
import { PlanSummaryComponent } from './pages/register/plan-summary/plan-summary.component';
import { MetadataFormComponent } from './pages/metadata-form/metadata-form.component';
import { ProjectSettingsComponent } from './pages/project-settings/project-settings.component';
import { MetadataComponent } from './pages/metadata/metadata.component';
import { PermissionsComponent } from './pages/permissions/permissions.component';
import { RestorefilesComponent } from './pages/restorefiles/restorefiles.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { ArchieveFilesComponent } from './pages/archieve-files/archieve-files.component';
import { SignatureComponent } from './pages/signature/signature.component';
import { SearchedfilesComponent } from './pages/searchedfiles/searchedfiles.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { MainLayoutComponent } from './pages/layout/main-layout/main-layout.component';
import { HeaderLandingAboutComponent } from './pages/layout/header-landing-about/header-landing-about.component';
import { FooterLayoutComponent } from './pages/layout/footer-layout/footer-layout.component';
import { ErrorComponentComponent } from './pages/error-component/error-component.component';
import { ArchievedFilesComponent } from './pages/archieved-files/archieved-files.component';
import { DocumentCreationComponent } from './pages/document-creation/document-creation.component';

const routes: Routes = [
	{
		path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full', data: { state: 'forgot-password'}
	},
	{
		path: '', component: LoginComponent, pathMatch: 'full', data: { state: 'login'}
	},
	// {
	// 	path: 'about', component: AboutPageComponent, pathMatch: 'full', data: { state: 'about-page'}
	// },
	// {
	// 	path: '', component: LandingPageComponent, pathMatch: 'full', data: { state: 'landing-page'}
	// },
	{
		path: 'contact-info', component: ContactInfoComponent, pathMatch: 'full', data: { state: 'contact-info'}
	},
	{
		path: 'contact-info', component: ContactInfoComponent, pathMatch: 'full', data: { state: 'contact-info'}
	},
	{
		path: '',
		component: MainLayoutComponent, 
		children: [ 
			{
				path: 'dashboard', component: DashboardComponent, pathMatch: 'full', data: { state: 'dashboard'}
			},
			{
				path: 'favourite', component: FavouriteComponent, pathMatch: 'full', data: { state: 'favourite'}
			},
			{
				path: 'header', component: HeaderComponent, pathMatch: 'full', data: { state: 'header'}
			},
			{
				path: 'sidebar', component: SidebarComponent, pathMatch: 'full', data: { state: 'sidebar'}
			},
			{
				path: 'shared', component: SharedComponent, pathMatch: 'full', data: { state: 'shared'}
			},	
			{
				path: 'recyclebin', component: RecyclebinComponent, pathMatch: 'full', data: { state: 'recyclebin'}
			},
			{
				path: 'footer', component: FooterComponent, pathMatch: 'full', data: { state: 'footer'}
			},
			{
				path: 'signup', component: SignupComponent, pathMatch: 'full', data: { state: 'signup'}
			},
			{
				path: 'packages', component: PackagesComponent, pathMatch: 'full', data: { state: 'packages'}
			},
			{
				path: 'business-info', component: BusinessInfoComponent, pathMatch: 'full', data: { state: 'business-info'}
			},
			
			{
				path: 'business-creation', component: BusinessCreationComponent, pathMatch: 'full', data: { state: 'business-creation'}
			},
			{
				path: 'profile-settings', component: ProfileSettingsComponent, pathMatch: 'full', data: { state: 'profile-settings'}
			},
			{
				path: 'catalogue', component: CatalogueComponent, pathMatch: 'full', data: { state: 'catalogue'}
			},
			{
				path: 'subscription-plan', component: SubscriptionPlanComponent, pathMatch: 'full', data: { state: 'subscription-plan'}
			},
			{
				path: 'reports', component: ReportsComponent, pathMatch: 'full', data: { state: 'reports'}
			},
			{
				path: 'folders/:id', component: FoldersComponent, pathMatch: 'full', data: { state: 'folders'}
			},
			{
				path: 'folders/:id/:id', component: FoldersComponent, pathMatch: 'full', data: { state: 'folders'}
			},
			{
				path: 'projects', component: ProjectsComponent, pathMatch: 'full', data: { state: 'projects'}
			},
			{
				path: 'domain-permission', component: DomainPermissionComponent, pathMatch: 'full', data: { state: 'domain-permission'}
			},
			{
				path: 'domain-search', component: DomainSearchComponent, pathMatch: 'full', data: { state: 'domain-search'}
			},
			{
				path: 'plan-summary', component: PlanSummaryComponent, pathMatch: 'full', data: { state: 'plan-summary'}
			},
			{
				path: 'metadata', component: MetadataComponent, pathMatch: 'full', data: { state: 'metadata'}
			},
			{
				path: 'metadata-form', component: MetadataFormComponent, pathMatch: 'full', data: { state: 'metadata-form'}
			},
			{
				path: 'metadata-form/:id', component: MetadataFormComponent, pathMatch: 'full', data: { state: 'metadata-form'}
			},
			{
				path: 'project-settings/:id', component: ProjectSettingsComponent, pathMatch: 'full', data: { state: 'project-settings'}
			},
			{
				path: 'permissions', component: PermissionsComponent, pathMatch: 'full', data: { state: 'permissions'}
			},
			{
				path: 'restorefiles/:id', component: RestorefilesComponent, pathMatch: 'full', data: { state: 'restorefiles'}
			},
			{
				path: 'notifications', component: NotificationsComponent, pathMatch: 'full', data: { state: 'notifications'}
			},
			{
				path: 'archieve-files', component: ArchieveFilesComponent, pathMatch: 'full', data: { state: 'archieve-files'}
			},
			{
				path: 'signature', component: SignatureComponent, pathMatch: 'full', data: { state: 'signature'}
			},
			{
				path: 'searched-files', component: SearchedfilesComponent, pathMatch: 'full', data: { reuseRoute: true, reuseKey: 'some_key', state: 'searched-files' },
			},
			{
				path: 'landning-header', component: HeaderLandingAboutComponent, pathMatch: 'full', data: { state: 'landning-header'}
			},
			{
				path: 'footer-layout', component: FooterLayoutComponent, pathMatch: 'full', data: { state: 'footer-layout'}
			},
			{
				path: 'error-component', component: ErrorComponentComponent, pathMatch: 'full', data: { state: 'error-component'}
			},
			{
				path: 'archieved-files/:id', component: ArchievedFilesComponent, pathMatch: 'full', data: { state: 'archieved-files'}
			},
			{
				path: 'document-creation', component: DocumentCreationComponent, pathMatch: 'full', data: { state: 'document-creation'}
			}
		],
		canActivate: [AuthGuard]
	}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
